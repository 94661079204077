import React, { Fragment } from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import SideBar from "../Commons/SideBar";
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { __ } from "../../i18n/translation";
import { BlueLink, DefLink } from "../../Theme/components/Commons";
import Path from "../../Path";
import Typography from "@mui/material/Typography";
import Switch from '@mui/material/Switch';
import { theme } from "../../Theme/theme";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import StageDialog from "./StageDialog";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const Project = () => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (value) => {
        setOpen(false);
    };
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { title: __('ProjectOverview') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 8 },
                        React.createElement(Paper, { sx: {
                                p: 3,
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                                borderBottom: `1px solid ${theme.palette.grey[300]}`
                            } },
                            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                                React.createElement(Box, { component: "div" },
                                    React.createElement(Typography, { variant: "body1", sx: { fontWeight: 600, fontSize: '18px' } }, "\u0160ilumos tinkl\u0173 savitarna"),
                                    React.createElement(Typography, { variant: "body1", sx: { fontWeight: 400, fontSize: '14px' } }, "Vilniaus \u0161ilumos tinklai, AB | Sukurtas: 3 metai | Sugai\u0161ta valand\u0173: 25.00 val.")),
                                React.createElement(Switch, null))),
                        React.createElement(Paper, { sx: { p: 3, borderRadius: 0, backgroundColor: `${theme.palette.grey[100]}` } },
                            React.createElement(Typography, { variant: "body1", sx: {
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    textTransform: 'uppercase',
                                    color: `${theme.palette.grey[800]}`
                                } }, "Atsakingas asmuo"),
                            React.createElement(Typography, { variant: "body1", sx: { fontWeight: 400, fontSize: '14px' } }, "Domas Stanelis"),
                            React.createElement(Typography, { variant: "body1", sx: { fontWeight: 400, fontSize: '14px' } }, "domantas.stanelis@chc.lt"),
                            React.createElement(Typography, { variant: "body1", sx: { fontWeight: 400, fontSize: '14px' } }, "+37065534510")),
                        React.createElement(Paper, { sx: {
                                p: 3,
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderTop: `1px solid ${theme.palette.grey[300]}`
                            } },
                            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
                                React.createElement(BlueLink, { to: `${Path.Projects}/new` }, "Tvarkyti projekt\u0105"),
                                React.createElement(Button, { color: "secondary", onClick: handleClickOpen },
                                    React.createElement(AddIcon, null),
                                    "Prid\u0117ti etap\u0105"))),
                        React.createElement(Paper, { sx: { mt: 3 } },
                            React.createElement(Box, { sx: { p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
                                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' } },
                                    React.createElement(CheckCircleOutlineIcon, { sx: { fontSize: 40, color: `${theme.palette.grey[400]}` } }),
                                    React.createElement(Box, { component: 'div', sx: { ml: 2 } },
                                        React.createElement(Typography, { variant: "body1", sx: { fontWeight: 600, fontSize: '18px' } }, "Etapas 1 - Pavadinimas"),
                                        React.createElement(Typography, { variant: "body1", sx: { fontWeight: 400, fontSize: '14px' } }, "Kategorija Programavimas | Numatomas laikas: 25 val."))),
                                React.createElement(Button, { color: "secondary", onClick: handleClickOpen },
                                    React.createElement(EditIcon, null))),
                            React.createElement(Box, { sx: {
                                    p: 2,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderTop: `1px solid ${theme.palette.grey[300]}`
                                } },
                                React.createElement(Box, { component: 'div' },
                                    React.createElement(Typography, { variant: "body1", sx: { fontWeight: 600, fontSize: '16px' } }, "U\u017Eduotys")),
                                React.createElement(Button, { color: "secondary", onClick: handleClickOpen },
                                    React.createElement(AddIcon, null),
                                    "Prid\u0117ti u\u017Eduot\u012F")),
                            React.createElement(Box, { sx: { p: 2, borderTop: `1px solid ${theme.palette.grey[300]}` } },
                                React.createElement(Box, { component: 'div' },
                                    React.createElement(DefLink, { to: `${Path.Projects}/new` }, "Darbai"))),
                            React.createElement(Box, { sx: { p: 2, borderTop: `1px solid ${theme.palette.grey[300]}` } },
                                React.createElement(Box, { component: 'div' },
                                    React.createElement(DefLink, { to: `${Path.Projects}/new` }, "Darbai"))))),
                    React.createElement(Grid, { item: true, xs: 4 })))),
        React.createElement(StageDialog, { open: open, onClose: handleClose })));
};
export default Project;
