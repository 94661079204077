import { useFetch } from "../ApiService";
import Path from "../../Path";
import { useMutation, useQuery } from "react-query";
export const useClients = ({ name = '', limit = '', page = '' }) => {
    const getClient = () => {
        return useFetch.get(`${Path.ApiClients}/?name=${name}&limit=${limit}&page=${page}`);
    };
    return useQuery(['clients'], getClient);
};
export const useClient = ({ clientId }) => {
    const fetchClientById = () => {
        return useFetch.get(`${Path.ApiClients}/${clientId}`);
    };
    return useQuery(['clients', clientId], fetchClientById);
};
export const useCreateClient = () => {
    const fetchUser = (values) => {
        return useFetch.post(`${Path.ApiClients}`, { client: values });
    };
    return useMutation((values) => fetchUser(values), {});
};
export const useClientUpdate = () => {
    const fetchPass = (values) => {
        return useFetch.put(`${Path.ApiClients}`, { client: values });
    };
    return useMutation((values) => fetchPass(values), {});
};
export const removeClient = () => {
    const fetchUser = ({ clientId }) => {
        return useFetch.del(`${Path.ApiClients}/${clientId}`);
    };
    return useMutation((clientId) => fetchUser(clientId), {});
};
