import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Fragment, useEffect } from "react";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import ProfileForm from "./ProfileForm";
import { AppContext } from "../../AppContext";
import { useHistory } from "react-router";
import { UpdateMember } from "./useUsers";
import Path from "../../Path";
const CreateUser = () => {
    const { role } = React.useContext(AppContext);
    const history = useHistory();
    const { mutate, data: useUpdateData, isError, isSuccess: UpdateUserIsSuccess } = UpdateMember();
    useEffect(() => {
        if (UpdateUserIsSuccess && useUpdateData) {
            history.push(`${Path.Users}/${useUpdateData.user_id}`);
        }
    }, [UpdateUserIsSuccess, useUpdateData]);
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { title: __('Member') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Paper, { sx: { pl: 4, pb: 4 } },
                    React.createElement(ProfileForm, { user: { user_id: 0 }, type: 1, role: role, mutate: mutate }))))));
};
export default CreateUser;
