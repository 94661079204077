import * as React from "react";
import { useState, useEffect, Fragment } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { __ } from "../../i18n/translation";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DatePickerMui, InputLabelC, InputLabelS, InputSelectField, InputSelectSearch, InputTextField } from "../../Theme/components/InputTextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Autocomplete, Button, TextField } from "@mui/material";
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from "@mui/icons-material/Delete";
import { theme } from "../../Theme/theme";
import AddIcon from '@mui/icons-material/Add';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import SellerForm from "./SellerForm";
import { useSellers } from "./useExpenses";
const ExpensesForm = ({ expense }) => {
    const today = moment().format("YYYY-MM-DD");
    const { data: Sellers, isSuccess: isSuccessSellers, isError } = useSellers({ s: '' });
    const [sellerName, setSellerName] = useState((expense === null || expense === void 0 ? void 0 : expense.seller_name) ? { seller_name: expense === null || expense === void 0 ? void 0 : expense.seller_name } : null);
    const [dateCreated, setDateCreated] = useState();
    const [dateGet, setDateGet] = useState();
    const [dateReg, setDateReg] = useState();
    const [modal, setModal] = useState({ open: false });
    const { handleSubmit, control, formState: { errors }, register, setValue, watch } = useForm({
        defaultValues: {
            seller_name: '',
            seller_code: '',
            seller_vat: '',
            seller_country: '',
            invoice_number: '',
            seller_id: '',
            invoice_type: 1,
            category: 3,
            created: null,
            get_date: null,
            reg_date: null,
            special_pay: false,
            expensesFields: [{
                    sum: 0,
                    vatType: 1,
                    vatValue: 21.00,
                    vat: 0,
                }],
        }
    });
    const vatOption = [
        0,
        21.00,
        9.00,
        5.00,
    ];
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            var _a, _b;
            let totalUp = 0;
            if (type === 'change') {
                if (name) {
                    const cartIndexName = name.split('.');
                    if (cartIndexName[2] === 'sum' || cartIndexName[2] === 'vatType') {
                        const index = parseInt(cartIndexName[1]);
                        if (value.expensesFields && typeof value.expensesFields[index] !== 'undefined') {
                            const sum = ((_a = value.expensesFields[index]) === null || _a === void 0 ? void 0 : _a.sum) || 0;
                            const vatType = parseInt(String((_b = value.expensesFields[index]) === null || _b === void 0 ? void 0 : _b.vatType));
                            setValue(`expensesFields.${cartIndexName[1]}.vatValue`, vatOption[vatType]);
                            setValue(`expensesFields.${cartIndexName[1]}.vat`, (sum * (vatOption[vatType] / 100)).toFixed(2));
                        }
                    }
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    const { fields, append, remove } = useFieldArray({
        name: "expensesFields",
        control,
    });
    const Cancel = () => {
        setModal({ open: false });
    };
    const onSubmit = handleSubmit((values) => {
        console.log(values);
    });
    const AddSeller = () => {
        setModal({
            open: true,
        });
    };
    const Type = [
        { value: 1, label: 'PVM sąskaita faktūta ' },
        { value: 2, label: 'Debetinė PVM sąskaita faktūta' },
        { value: 3, label: 'Kreditinė PVM sąskaita faktūta' },
        { value: 4, label: 'Anuliuota' },
    ];
    const Category = [
        { value: 1, label: 'Nuomos paslaugos' },
        { value: 2, label: 'Programine įranga' },
        { value: 3, label: 'Buitinės išlaidos' },
        { value: 4, label: 'Reprezentacines išlaidos' },
        { value: 5, label: 'Serverių nuomos paslaugos' },
        { value: 6, label: 'Dizaino paslaugos' },
        { value: 7, label: 'Vertimo paslaugos' },
    ];
    return (React.createElement(Fragment, null,
        React.createElement(Dialog, { open: modal.open, onClose: Cancel, "aria-labelledby": "seller-dialog-title", "aria-describedby": "seller-dialog-description" },
            React.createElement(DialogTitle, { id: "seller-dialog-title", sx: { fontSize: '20px' } }, "Pardav\u0117jas"),
            React.createElement(DialogContent, null,
                React.createElement(SellerForm, { cancel: Cancel, setValue: setValue, setSellerName: setSellerName }))),
        React.createElement("form", { onSubmit: onSubmit },
            React.createElement(Grid, { container: true, spacing: 4, sx: { pr: 4, mt: 1, mb: 4 } },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "h3", sx: { fontWeight: 500, fontSize: '18px', textTransform: "uppercase" } }, "PVM s\u0105skaitos fakt\u016Bros duomenys"))),
            React.createElement(Grid, { container: true, spacing: 4, sx: { pr: 4 } },
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6 },
                    React.createElement(FormControl, { sx: { mt: '30px' }, size: "small", fullWidth: true, error: errors.seller_name && errors.seller_name.type === "required" },
                        React.createElement(InputLabelC, { sx: { top: '-30px' }, shrink: true, htmlFor: "bootstrap-input" }, "Pardav\u0117jas "),
                        React.createElement(Controller, { control: control, name: "seller_name", rules: { required: true }, render: () => (React.createElement(Autocomplete, { disablePortal: true, options: Sellers || [], value: sellerName, 
                                // defaultValue={ (expense?.seller_name) ? {seller_name: expense?.seller_name} : { seller_name: expense?.seller_name}}
                                getOptionLabel: (option) => option.seller_name || '', isOptionEqualToValue: (option, value) => option.seller_name === value.seller_name, fullWidth: true, onChange: (event, value) => {
                                    value && setValue('seller_name', value.seller_name);
                                    value && setValue('seller_code', value.seller_code);
                                    value && setValue('seller_vat', value.seller_vat);
                                    value && setValue('seller_id', value.seller_id);
                                    value && setValue('seller_country', value.seller_country);
                                }, renderInput: (params) => React.createElement(InputSelectSearch, Object.assign({ error: errors.seller_name && errors.seller_name.type === "required" }, params)) })) }),
                        errors.seller_name && errors.seller_name.type === "required" &&
                            React.createElement(FormHelperText, null, __('ReqField'))),
                    React.createElement(Box, { component: "main", sx: {} },
                        React.createElement(Button, { sx: { mt: 1 }, variant: "outlined", color: "primary", size: "small", onClick: () => AddSeller() },
                            "\u012Evesti nauj\u0105 ",
                            React.createElement(AddIcon, { sx: { fontSize: '16px' } }))),
                    React.createElement(FormControl, { fullWidth: true, variant: "outlined", error: errors.invoice_number && errors.invoice_number.type === "required" },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, "Serija ir numeris"),
                        React.createElement(Controller, { control: control, defaultValue: '', name: "invoice_number", rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.invoice_number && errors.invoice_number.type === "required", 
                                // margin="dense"
                                autoComplete: "off" }, field)) }),
                        errors.invoice_number && errors.invoice_number.type === "required" &&
                            React.createElement(FormHelperText, null, __('ReqField'))),
                    React.createElement(FormControl, { fullWidth: true, size: "small" },
                        React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, "S\u0105skaitos tipas"),
                        React.createElement(Controller, { control: control, name: "invoice_type", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({ onClose: (e) => {
                                } }, field), Type.map((term) => {
                                return (React.createElement(MenuItem, { key: term.value, value: term.value }, term.label));
                            })) })),
                    React.createElement(FormControl, { fullWidth: true, size: "small" },
                        React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, "I\u0161laid\u0173 kategorija"),
                        React.createElement(Controller, { control: control, name: "category", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({ onClose: (e) => {
                                } }, field), Category.map((term) => {
                                return (React.createElement(MenuItem, { key: term.value, value: term.value }, term.label));
                            })) }))),
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6 },
                    React.createElement("div", null,
                        React.createElement(FormControl, { size: "small", error: errors.created && errors.created.type === "required" },
                            React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, "Data"),
                            React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                                React.createElement(DatePickerMui, Object.assign({}, register('created', {
                                    required: true
                                }), { inputFormat: "YYYY-MM-DD", value: dateCreated || null, onChange: (newValue) => {
                                        var _a;
                                        setDateCreated(newValue);
                                        if (newValue) {
                                            setValue('created', (_a = newValue.format) === null || _a === void 0 ? void 0 : _a.call(newValue, "YYYY-MM-DD"), {
                                                shouldValidate: true,
                                                shouldDirty: true
                                            });
                                        }
                                    }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { error: errors.created && errors.created.type === "required" })) }))),
                            errors.created && errors.created.type === "required" &&
                                React.createElement(FormHelperText, null, __('ReqField')))),
                    React.createElement("div", null,
                        React.createElement(FormControl, { size: "small" },
                            React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, "Preki\u0173 / paslaug\u0173 gavimo data"),
                            React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                                React.createElement(DatePickerMui, Object.assign({}, register('get_date', {}), { inputFormat: "YYYY-MM-DD", value: dateGet || null, onChange: (newValue) => {
                                        var _a;
                                        setDateCreated(newValue);
                                        if (newValue) {
                                            setValue('get_date', (_a = newValue.format) === null || _a === void 0 ? void 0 : _a.call(newValue, "YYYY-MM-DD"), {
                                                shouldValidate: true,
                                                shouldDirty: true
                                            });
                                        }
                                    }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params)) }))))),
                    React.createElement("div", null,
                        React.createElement(FormControl, { size: "small" },
                            React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, "Registravimo apskaitoje data"),
                            React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                                React.createElement(DatePickerMui, Object.assign({}, register('reg_date', {}), { inputFormat: "YYYY-MM-DD", value: dateReg || null, onChange: (newValue) => {
                                        var _a;
                                        setDateCreated(newValue);
                                        if (newValue) {
                                            setValue('reg_date', (_a = newValue.format) === null || _a === void 0 ? void 0 : _a.call(newValue, "YYYY-MM-DD"), {
                                                shouldValidate: true,
                                                shouldDirty: true
                                            });
                                        }
                                    }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params)) }))))),
                    React.createElement("div", null,
                        React.createElement(FormControl, null,
                            React.createElement(Controller, { control: control, name: "special_pay", render: ({ field }) => React.createElement(FormControlLabel, { control: React.createElement(Checkbox, Object.assign({}, field)), label: "Specialus apmokestinimas" }) }))))),
            React.createElement(Box, { component: "main", sx: {
                    mt: 4, mr: 4, mb: 2,
                    backgroundColor: `${theme.palette.grey[100]}`,
                    color: `${theme.palette.grey[900]}`,
                    borderTop: `solid 1px ${theme.palette.grey[200]}`,
                    borderBottom: `solid 1px ${theme.palette.grey[200]}`,
                    p: 1
                } },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, md: 3 }, "Apmokestinamoji vert\u0117"),
                    React.createElement(Grid, { item: true, md: 2 }, "Mokes\u010Dio kodas"),
                    React.createElement(Grid, { item: true, md: 3 }, "Mokes\u010Dio tarifas(%)"),
                    React.createElement(Grid, { item: true, md: 3 }, "PVM suma"),
                    React.createElement(Grid, { item: true, md: 1 }))),
            fields.map((field, index) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
                return (React.createElement(Box, { key: field.id, component: "main", sx: {
                        borderBottom: `solid 1px ${theme.palette.grey[200]}`,
                        mr: 4,
                    } },
                    React.createElement(Grid, { container: true, spacing: 2, sx: {
                            pb: 1,
                            pt: 1
                        } },
                        React.createElement(Grid, { item: true, md: 3 },
                            React.createElement(FormControl, { variant: "outlined", fullWidth: true, error: (errors === null || errors === void 0 ? void 0 : errors.expensesFields) && ((_a = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _a === void 0 ? void 0 : _a.sum) &&
                                    ((_c = (_b = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _b === void 0 ? void 0 : _b.sum) === null || _c === void 0 ? void 0 : _c.type) === "required" },
                                React.createElement(Controller, { control: control, name: `expensesFields.${index}.sum`, rules: { required: true }, render: ({ field }) => {
                                        var _a, _b, _c;
                                        return React.createElement(InputTextField, Object.assign({ error: (errors === null || errors === void 0 ? void 0 : errors.expensesFields) && ((_a = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _a === void 0 ? void 0 : _a.sum) &&
                                                ((_c = (_b = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _b === void 0 ? void 0 : _b.sum) === null || _c === void 0 ? void 0 : _c.type) === "required", margin: "dense", autoComplete: "off", fullWidth: true }, field));
                                    } }),
                                (errors === null || errors === void 0 ? void 0 : errors.expensesFields) && ((_d = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _d === void 0 ? void 0 : _d.sum) &&
                                    ((_f = (_e = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _e === void 0 ? void 0 : _e.sum) === null || _f === void 0 ? void 0 : _f.type) === "required" &&
                                    React.createElement(FormHelperText, { style: {
                                            marginRight: 0,
                                            width: '100%',
                                            marginBottom: -23
                                        } }, __('ReqField')))),
                        React.createElement(Grid, { item: true, md: 2 },
                            React.createElement(FormControl, { fullWidth: true, size: "small" },
                                React.createElement(Controller, { control: control, name: `expensesFields.${index}.vatType`, rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({ style: { marginTop: 0 }, fullWidth: true }, field),
                                        React.createElement(MenuItem, { value: "1" }, "PVM1"),
                                        React.createElement(MenuItem, { value: "2" }, "PVM2"),
                                        React.createElement(MenuItem, { value: "3" }, "PVM3"),
                                        React.createElement(MenuItem, { value: "0" }, "PVM4")) }))),
                        React.createElement(Grid, { item: true, md: 3 },
                            React.createElement(FormControl, { variant: "outlined", fullWidth: true, error: (errors === null || errors === void 0 ? void 0 : errors.expensesFields) && ((_g = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _g === void 0 ? void 0 : _g.sum) &&
                                    ((_j = (_h = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _h === void 0 ? void 0 : _h.sum) === null || _j === void 0 ? void 0 : _j.type) === "required" },
                                React.createElement(Controller, { control: control, name: `expensesFields.${index}.vatValue`, rules: { required: true }, render: ({ field }) => {
                                        var _a, _b, _c;
                                        return React.createElement(InputTextField, Object.assign({ error: (errors === null || errors === void 0 ? void 0 : errors.expensesFields) && ((_a = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _a === void 0 ? void 0 : _a.vatValue) &&
                                                ((_c = (_b = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _b === void 0 ? void 0 : _b.vatValue) === null || _c === void 0 ? void 0 : _c.type) === "required", margin: "dense", autoComplete: "off", fullWidth: true }, field));
                                    } }),
                                (errors === null || errors === void 0 ? void 0 : errors.expensesFields) && ((_k = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _k === void 0 ? void 0 : _k.vatValue) &&
                                    ((_m = (_l = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _l === void 0 ? void 0 : _l.vatValue) === null || _m === void 0 ? void 0 : _m.type) === "required" &&
                                    React.createElement(FormHelperText, { style: {
                                            marginRight: 0,
                                            width: '100%',
                                            marginBottom: -23
                                        } }, __('ReqField')))),
                        React.createElement(Grid, { item: true, md: 3 },
                            React.createElement(FormControl, { variant: "outlined", fullWidth: true, error: (errors === null || errors === void 0 ? void 0 : errors.expensesFields) && ((_o = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _o === void 0 ? void 0 : _o.vat) &&
                                    ((_q = (_p = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _p === void 0 ? void 0 : _p.vat) === null || _q === void 0 ? void 0 : _q.type) === "required" },
                                React.createElement(Controller, { control: control, name: `expensesFields.${index}.vat`, rules: { required: true }, render: ({ field }) => {
                                        var _a, _b, _c;
                                        return React.createElement(InputTextField, Object.assign({ error: (errors === null || errors === void 0 ? void 0 : errors.expensesFields) && ((_a = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _a === void 0 ? void 0 : _a.vat) &&
                                                ((_c = (_b = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _b === void 0 ? void 0 : _b.vat) === null || _c === void 0 ? void 0 : _c.type) === "required", margin: "dense", autoComplete: "off", fullWidth: true }, field));
                                    } }),
                                (errors === null || errors === void 0 ? void 0 : errors.expensesFields) && ((_r = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _r === void 0 ? void 0 : _r.vat) &&
                                    ((_t = (_s = errors === null || errors === void 0 ? void 0 : errors.expensesFields[index]) === null || _s === void 0 ? void 0 : _s.vat) === null || _t === void 0 ? void 0 : _t.type) === "required" &&
                                    React.createElement(FormHelperText, { style: {
                                            marginRight: 0,
                                            width: '100%',
                                            marginBottom: -23
                                        } }, __('ReqField')))),
                        React.createElement(Grid, { item: true, md: 1 },
                            React.createElement(Button, { type: "button", onClick: () => remove(index) },
                                React.createElement(DeleteIcon, null))))));
            }),
            React.createElement("div", { className: "append" },
                React.createElement(Button, { variant: "contained", color: "success", sx: { color: 'white' }, style: { marginTop: 10, marginBottom: 15 }, onClick: () => append({
                        sum: 0,
                        vatType: 1,
                        vatValue: 21.00,
                        vat: 0,
                    }) }, "Prideti eilut\u0119")),
            React.createElement(Box, { component: "main", sx: {
                    mr: 4,
                    display: 'flex',
                    justifyContent: 'end'
                } },
                React.createElement(Button, { type: "submit", variant: "contained", color: "primary" },
                    " ",
                    __('Save'))))));
};
export default ExpensesForm;
