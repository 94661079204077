import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import Path from "../../Path";
import { __ } from "../../i18n/translation";
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import { InputTextField, InputLabelC } from "../../Theme/components/InputTextField";
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { DefLink } from "../../Theme/components/Commons";
import { useLogin } from "../Users/useUsers";
const FormWithPassword = () => {
    const { handleSubmit, control, formState: { errors } } = useForm();
    const { mutate, isError } = useLogin();
    const onSubmit = handleSubmit((values) => {
        mutate(values);
    });
    return (React.createElement("form", { onSubmit: onSubmit },
        React.createElement(Box, { sx: { mt: 2, mb: 2, wight: '100%' } }),
        isError && React.createElement(Alert, { severity: "error" },
            "  ",
            __('InvalidLogin'),
            " "),
        React.createElement(FormControl, { fullWidth: true, error: errors.email && errors.email.type === "required" },
            React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('LoginEmail')),
            React.createElement(Controller, { control: control, name: "email", defaultValue: '', rules: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.email && errors.email.type === "required" }, field)) }),
            errors.email && errors.email.type === "required" && React.createElement(FormHelperText, null, __('ReqField'))),
        React.createElement(FormControl, { fullWidth: true, variant: "outlined", error: errors.password && errors.password.type === "required" },
            React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Password')),
            React.createElement(Controller, { control: control, defaultValue: '', name: "password", rules: { required: true, minLength: 8 }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.password && errors.password.type === "required", 
                    // margin="dense"
                    autoComplete: "off", type: "password" }, field)) }),
            errors.password && errors.password.type === "required" &&
                React.createElement(FormHelperText, null, __('ReqField'))),
        React.createElement(Box, { sx: { mt: 2 } },
            React.createElement(Button, { sx: { width: 1 }, type: "submit", variant: "contained", color: "primary" },
                " ",
                __('login'))),
        React.createElement(Divider, { sx: { mt: 2, mb: 2, borderBottom: 'solid 1px #dfe3e9', wight: '100%' } }),
        React.createElement(DefLink, { to: Path.ForgotPass }, __('forgotYourPassword'))));
};
export default FormWithPassword;
