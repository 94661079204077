import * as React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { theme } from '../../Theme/theme';
import { DefLink } from "../../Theme/components/Commons";
const BoxTitle = props => {
    const { title, count, countTitle, link, linkTitle } = props;
    return (React.createElement(Box, { px: 4, py: 3, sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 'solid 1px', borderColor: theme.palette.grey[300] } },
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' } },
            React.createElement(Typography, { variant: "h3" }, title),
            count && React.createElement(Divider, { sx: { borderColor: theme.palette.grey[500], margin: '6px 12px', height: "18px" }, orientation: "vertical", flexItem: true }),
            React.createElement(Typography, { sx: { color: theme.palette.grey[700], lineHeight: 2.2 }, variant: "subtitle2" },
                count,
                " ",
                countTitle)),
        React.createElement(Box, null, link && React.createElement(DefLink, { to: link }, linkTitle))));
};
export default BoxTitle;
