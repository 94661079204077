import { useFetch } from "../ApiService";
import Path from "../../Path";
import { useMutation, useQuery } from "react-query";
export const useLogin = () => {
    const fetchPass = (values) => {
        return useFetch.post(`${Path.ApiLogin}`, { user: values });
    };
    return useMutation((values) => fetchPass(values), {
        onSuccess: (data) => {
            if (data.IsValid) {
                return window.location.replace('/');
            }
        },
        onError: (error) => {
            console.log(error.status);
        },
    });
};
export const useMembers = () => {
    const fetchMembers = () => {
        return useFetch.get(`${Path.ApiMembers}`);
    };
    return useQuery(['members'], fetchMembers);
};
export const useMember = ({ userId }) => {
    const fetchMemberById = () => {
        return useFetch.get(`${Path.ApiMembers}/${userId}`);
    };
    return useQuery(['members', userId], fetchMemberById);
};
export const useProfile = () => {
    const fetchMember = () => {
        return useFetch.get(`${Path.ApiProfile}`);
    };
    return useQuery(['member'], fetchMember);
};
export const usePassword = () => {
    const fetchPass = (values) => {
        return useFetch.post(`${Path.ApiPass}`, values);
    };
    return useMutation((values) => fetchPass(values), {});
};
export const useGetFile = (type, id) => {
    const fetchFile = () => {
        return useFetch.get(`${Path.ApiGetFile}/${type}/${id}`);
    };
    return useQuery(['files', id], fetchFile);
};
const FD = (values) => {
    var _a;
    let fd = new FormData();
    fd.append("user[user_id]", `${values.user_id}`);
    fd.append("user[email]", `${values.email}`);
    fd.append("user[phone]", `${values.phone}`);
    fd.append("user[position]", `${values.position}`);
    fd.append("user[role]", `${values.role}`);
    fd.append("user[name]", `${values.name}`);
    fd.append("user[surename]", `${values.surename}`);
    if (values.password) {
        fd.append("user[password]", `${values.password}`);
    }
    fd.append("user[avatar]", `${values.avatar}`);
    if (((_a = values.files) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        values.files.map((file) => {
            fd.append("file", file);
        });
    }
    return fd;
};
export const UpdateMember = () => {
    const fetchUser = (values) => {
        return useFetch.post(`${Path.ApiProfileUp}`, FD(values), {});
    };
    return useMutation((values) => fetchUser(values), {});
};
export const removeMember = () => {
    const fetchUser = (userId) => {
        return useFetch.del(`${Path.ApiProfile}/${userId.userId}`);
    };
    return useMutation((userId) => fetchUser(userId), {});
};
