import styled from "styled-components";
import { theme } from '../theme';
export const LoginThumb = styled.div `
  background: url(${props => props.img}) top left no-repeat;
  background-size: cover;
  color: white;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-top-right-radius: ${theme.shape.borderRadius};
  border-bottom-right-radius: ${theme.shape.borderRadius};
  div {
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;
  }
  h3 {
    color: white;
  }
  p {
    color: white;
  }
`;
