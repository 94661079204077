import * as React from "react";
import { Fragment } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { LoginThumb } from '../../Theme/components/LoginThumb';
import FormWithPassword from "./LoginForm";
import { __ } from "../../i18n/translation";
import loginImg from '../../media/login.jpg';
import Typography from '@mui/material/Typography';
const Login = () => {
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Container, { maxWidth: "md" },
            React.createElement(Box, { sx: { flexGrow: 1, marginTop: "10%" } },
                React.createElement(Paper, null,
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(Box, { sx: { padding: "30px" } },
                                React.createElement(Typography, { variant: "h2", sx: { fontSize: '1.5rem', fontWeight: 500 } }, __('login')),
                                React.createElement(FormWithPassword, null))),
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(LoginThumb, { img: loginImg },
                                React.createElement("div", null,
                                    React.createElement(Typography, { variant: "h2", sx: { fontSize: '1.5rem', fontWeight: 600 } }, __('logH')),
                                    React.createElement("p", null, __('logP')))))))))));
};
export default Login;
