import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Fragment, useEffect, useState } from "react";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import BoxTitle from "../Commons/BoxTitle";
import ClientLine from "./ClientLine";
import { removeClient, useClients } from "./useClients";
import { theme } from "../../Theme/theme";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
const Client = () => {
    var _a;
    const { isLoading, isError, data, error, refetch } = useClients({});
    const [modal, setModal] = useState({ open: false, client: { client_id: 0, company_name: '' } });
    const remove = removeClient();
    const Cancel = () => {
        setModal({ open: false, client: { client_id: 0, company_name: '' } });
    };
    const Continue = () => {
        remove.mutate({ clientId: modal.client.client_id });
        setModal({ open: false, client: { client_id: 0, company_name: '' } });
    };
    const RemoveContact = (client) => {
        setModal({
            open: true,
            client: {
                client_id: client.client_id,
                company_name: client.company_name
            }
        });
        console.log('hi');
    };
    useEffect(() => {
        if (remove.isSuccess) {
            refetch();
        }
    }, [remove.isSuccess]);
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Dialog, { open: modal.open, onClose: Cancel, "aria-labelledby": "session-dialog-title", "aria-describedby": "session-dialog-description" },
            React.createElement(DialogTitle, { id: "session-dialog-title" }, "Ar tikrai norit pa\u0161alinti nar\u012F!"),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, { id: "session-dialog-description" }, (_a = modal.client) === null || _a === void 0 ? void 0 : _a.company_name)),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: Cancel, variant: "contained", color: "primary" },
                    " ",
                    __('Cancel')),
                React.createElement(Button, { onClick: Continue, variant: "contained", color: "error" },
                    " ",
                    __('Yes')))),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { btnType: 2, title: __('Clients') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Paper, null,
                            React.createElement(BoxTitle, { title: __('Clients'), count: data === null || data === void 0 ? void 0 : data.length }),
                            React.createElement(Box, { sx: { display: 'flex', backgroundColor: `${theme.palette.grey[200]}` } },
                                React.createElement(Box, { component: "main", sx: {
                                        flexGrow: 1,
                                        px: 4,
                                        py: 1,
                                        borderBottom: `solid 1px ${theme.palette.grey[300]}`
                                    } },
                                    React.createElement(Grid, { container: true, spacing: 2, sx: { alignItems: "center" } },
                                        React.createElement(Grid, { item: true, xs: 8 },
                                            React.createElement(Box, { sx: {} }, "Klientas")),
                                        React.createElement(Grid, { item: true, xs: 3 }, "\u012Emones kodas"),
                                        React.createElement(Grid, { item: true, xs: 1 }, "Veiksmai")))), data === null || data === void 0 ? void 0 :
                            data.map((client, index) => {
                                return (React.createElement(ClientLine, { key: index, client: client, RemoveContact: RemoveContact }));
                            }))))))));
};
export default Client;
