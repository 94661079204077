import * as React from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { __ } from "../../i18n/translation";
import FormControl from '@mui/material/FormControl';
import { InputTextField, InputLabelC, InputLabelS, InputSelectField, DatePickerField } from "../../Theme/components/InputTextField";
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import MenuItem from "@mui/material/MenuItem";
import { useClients } from "../Clients/useClients";
import { useMembers } from "../Users/useUsers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import { TextField } from "@mui/material";
const ProjectForm = ({ project, mutate }) => {
    const { handleSubmit, control, setValue, formState: { errors } } = useForm({
        defaultValues: {
            project_id: 0,
            title: '',
            client_id: '',
            resp_person: '',
            start: dayjs(),
            end: dayjs(''),
            assigned: '',
        },
    });
    const [startdate, setStart] = useState(dayjs());
    const [endDate, setEnd] = useState(null);
    const [selectedClient, setClient] = useState(0);
    const clients = useClients({});
    const clients_data = clients.data;
    const changeSymbol = (event) => {
        console.log(event.target.value);
    };
    // clients_data?.map((client: Client, index: number) => {
    //    console.log(useClient({clientId: client.client_id}));
    // })
    console.log(selectedClient);
    const { data: users } = useMembers();
    const onSubmit = handleSubmit((values) => {
        mutate(values);
        // console.log(values);
    });
    return (React.createElement("form", { onSubmit: onSubmit },
        React.createElement(FormControl, { fullWidth: true, variant: "outlined", error: errors.title && errors.title.type === "required" },
            React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Project')),
            React.createElement(Controller, { control: control, defaultValue: '', name: "title", rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.title && errors.title.type === "required", autoComplete: "off" }, field)) }),
            errors.title && errors.title.type === "required" &&
                React.createElement(FormHelperText, null, __('ReqField'))),
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement(FormControl, { fullWidth: true, variant: "outlined", sx: { mr: 1 }, error: errors.client_id && errors.client_id.type === "required" },
                React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, __('Company')),
                React.createElement(Controller, { control: control, name: "client_id", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({}, field), clients_data === null || clients_data === void 0 ? void 0 : clients_data.map((client, index) => {
                        return (React.createElement(MenuItem, { key: client.client_id, value: client.client_id, onClick: () => setClient(client.client_id) }, client.company_name));
                    })) }),
                errors.client_id && errors.client_id.type === "required" &&
                    React.createElement(FormHelperText, null, __('ReqField'))),
            React.createElement(FormControl, { fullWidth: true, variant: "outlined", error: errors.resp_person && errors.resp_person.type === "required" },
                React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, __('ResponsiblePerson')),
                React.createElement(Controller, { control: control, defaultValue: '', name: "resp_person", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({}, field), users === null || users === void 0 ? void 0 : users.map((user, index) => {
                        return (React.createElement(MenuItem, { key: user.user_id, value: user.user_id }, user.name));
                    })) }),
                errors.resp_person && errors.resp_person.type === "required" &&
                    React.createElement(FormHelperText, null, __('ReqField')))),
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement(FormControl, { fullWidth: true, variant: "outlined", sx: { mr: 1 }, error: errors.start && errors.start.type === "required" },
                React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('ProjectStart')),
                React.createElement(Controller, { control: control, name: "start", rules: { required: true }, render: ({ field }) => React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                        React.createElement(DatePickerField, { value: startdate, inputFormat: "YYYY-MM-DD", onChange: (newValue) => {
                                setStart(newValue);
                                setValue(`start`, newValue);
                            }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params)) })) }),
                errors.start && errors.start.type === "required" &&
                    React.createElement(FormHelperText, null, __('ReqField'))),
            React.createElement(FormControl, { fullWidth: true, variant: "outlined", sx: { mr: 1 } },
                React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('ProjectEnd')),
                React.createElement(Controller, { control: control, name: "end", render: ({ field }) => React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                        React.createElement(DatePickerField, { value: endDate, inputFormat: "YYYY-MM-DD", onChange: (newValue) => {
                                setEnd(newValue);
                                setValue(`end`, newValue);
                            }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params)) })) })),
            React.createElement(FormControl, { fullWidth: true, variant: "outlined", error: errors.assigned && errors.assigned.type === "required" },
                React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, __('AssignedTo')),
                React.createElement(Controller, { control: control, name: "assigned", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({}, field), users === null || users === void 0 ? void 0 : users.map((user, index) => {
                        return (React.createElement(MenuItem, { key: user.user_id, value: user.user_id }, user.name));
                    })) }),
                errors.assigned && errors.assigned.type === "required" &&
                    React.createElement(FormHelperText, null, __('ReqField')))),
        React.createElement(Box, { sx: { mt: 2 } },
            React.createElement(Button, { type: "submit", variant: "contained", color: "primary" },
                " ",
                __('Save')))));
};
export default ProjectForm;
