import * as React from "react";
import { createRoot } from 'react-dom/client';
import App from './App';
import 'whatwg-fetch';
// @ts-ignore
import { polyfillLoader } from 'polyfill-io-feature-detection';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";
polyfillLoader({
    "features": "Promise,default,es5,es6,fetch,Object.entries,Object.values,Array.prototype.includes",
    "onCompleted": main
});
const queryClient = new QueryClient();
const container = document.getElementById('pm-app');
const root = createRoot(container);
function main() {
    root.render(React.createElement(React.StrictMode, null,
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(App, null),
            React.createElement(ReactQueryDevtools, null))));
}
