import { useInvoice, useInvoiceUpdate } from "./useInvoices";
import React, { Fragment, useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import Paper from "@mui/material/Paper";
import InvoicesForm from "./InvoicesForm";
import { useParams } from "react-router-dom";
import ProfileSkeleton from "../Commons/ProfileSkeleton";
import SnackBar from "../Commons/Snackbar";
const Invoice = () => {
    const { id } = useParams();
    const invoiceId = parseInt(id);
    const { isLoading, isError, data: useInvoiceData, isSuccess: useInvoiceIsSuccess, refetch } = useInvoice({ invoiceId: invoiceId });
    const [invoiceData, setInvoice] = useState(useInvoiceData);
    const { mutate, data: useUpdateData, isSuccess: updateIsSuccess } = useInvoiceUpdate();
    useEffect(() => {
        if (updateIsSuccess && useUpdateData) {
            setInvoice(useUpdateData);
            refetch();
        }
        if (useInvoiceIsSuccess && useInvoiceData) {
            setInvoice(useInvoiceData);
        }
    }, [useUpdateData, useInvoiceData, useInvoiceIsSuccess, updateIsSuccess]);
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        useUpdateData && React.createElement(SnackBar, { type: 1, msg: __('UpdateSuccess') }),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { title: __('CreateClient') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Paper, { sx: { pl: 4, pb: 4 } },
                    isLoading && React.createElement(ProfileSkeleton, null),
                    invoiceData && React.createElement(InvoicesForm, { invoice: invoiceData.invoice.invoice, mutate: mutate }))))));
};
export default Invoice;
