export const lt = {
    month: {
        January: "Sausio mėn.",
        February: "Vasario mėn.",
        March: "Kovo mėn.",
        April: "Balandžio mėn.",
        May: "Gegužės mėn.",
        June: "Birželio mėn.",
        July: "Liepos mėn.",
        August: "Rugpjūčio mėn.",
        September: "Rugsėjo mėn.",
        October: "Spalio mėn.",
        November: "Lapkričio mėn.",
        December: "Gruodžio mėn.",
    },
    month1: {
        January: "Sausis",
        February: "Vasaris",
        March: "Kovas",
        April: "Balandis",
        May: "Gegužė",
        June: "Birželis",
        July: "Liepa",
        August: "Rugpjūtis",
        September: "Rugsėis",
        October: "Spalis",
        November: "Lapkritis",
        December: "Gruodis",
    },
    Jan: 'Sau',
    Feb: 'Vas',
    Mar: 'Kov',
    Apr: 'Bal',
    May: 'Geg',
    Jun: 'Bir',
    Jul: 'Lie',
    Aug: 'Rgp',
    Sep: 'Rgs',
    Oct: 'Spa',
    Nov: 'Lap',
    Dec: 'Gru',
    logH: 'Sveiki atvykę',
    logP: 'Idėjos ir technologija jūsų verslui tobulinti.',
    Password: 'Slaptažodis',
    Email: 'El. paštas',
    LoginEmail: 'Prisijunkite naudodami savo el. pašto adresą',
    Phone: 'Telefonas',
    Mob: 'Mobilus',
    login: "Prisijungti",
    forgotYourPassword: "Pamiršote slaptažodį?",
    InvalidLogin: "Neteisingas prisijungimas",
    emailExist: "Toks el. paštas yra registruotas",
    // validations
    ReqField: 'Laukas yra privalomas',
    MinField: 'Negali būti lygu 0',
    MinAmount: 'Negali būti lygu 0',
    BadFormat: 'Blogas formatas',
    MoreNum: 'Įveskite ne mažiau kaip %{num} simbolius',
    Overview: "Apžvalga",
    Statistics: "Statistika",
    Analytics: "Analizė",
    Clients: 'Klientai',
    Bills: "Sąskaitos",
    Members: 'Nariai',
    Member: 'Narys',
    Projects: "Projektai",
    Continue: "Tęsti",
    Continue1: "Jūsų sesija baigsis po 00.",
    Continue2: " min. Norite tęsti darbą?",
    AllEvents: "Visi įvykiai",
    Tasks: 'Užduotys',
    Users: "Vartotojai",
    DragFile: 'Vilkite dokumentą čia arba ieškokite kompiuteryje',
    ErrorFileFormat: 'Failų įkėlimo klaida: prisegti galima tik %{formats} tipo dokumentus, didžiausias leistinas dydis - %{size}MB.',
    Remove: 'Pašalinti',
    Name: "Vardas",
    SurName: "Pavardė",
    Profile: "Profilis",
    Photo: "Paveikslėlis",
    Role: 'Rolė',
    Administrator: 'Administratorius',
    Manager: 'Vadybininkas',
    User: 'Vartotojas',
    Client: 'Užsakovas',
    Position: 'Pareigos',
    Designer: 'Dizaineris',
    Programmer: 'Programuotojas',
    ProjectManager: 'Projektų vadovas',
    BusinessDevelopmentManager: 'Verslo plėtros vadovė',
    Accountant: 'Buhalteris',
    TheDirector: 'Direktorius',
    TheDirectorLabel: 'Direktorius (-ė)',
    Save: "Išsaugoti",
    UpdateSuccess: "Išsaugota sėkmingai",
    Yes: "Taip",
    No: "Ne",
    Cancel: 'Baigti',
    Back: 'Atgal',
    Close: 'Uždaryti',
    AboutClient: 'Apie klientą',
    CreateClient: 'Sukurti klientą',
    AdditionalData: 'Papildomi duomenys',
    CompanyData: 'Įmonės duomenys',
    CompanyPerson: 'Bendrovė / Asmuo',
    CompanyCode: 'Įmones kodas',
    CompanyVat: 'Įmonės PVM kodas',
    BankName: 'Banko pavadinimas',
    BankAccount: 'Banko sąskaita',
    Registration: 'Registracijos',
    Address: 'Adresas',
    TreatySignatory: 'Sutarties pasirašytojas',
    SignatureName: 'Pasirašančio vardas',
    SignatureLastName: 'Pasirašančio pavardė',
    InvoiceTerms: 'Apmokėjimo terminas',
    EmailInvoiceToSend: 'El. paštas sąskaitom siųsti',
    CorrespondenceAddress: 'Adresas korespondencijai',
    ResponsiblePersons: 'Atsakingi asmenys',
    City: "Miestas",
    PostCode: "Pašto kodas",
    Correspond: 'Korespondencijos',
    DueOnReceipt: 'Tapačią dieną',
    ThreeDays: '3 dienos',
    FiveDays: '5 dienos',
    SevenDays: '7 dienos',
    FourteenDays: '14 dienų',
    TwentyEightDays: '28 dienos',
    ThirtyDays: '30 dienų',
    FortyFiveDays: '45 dienos',
    SixtyDays: '60 dienų',
    NinetiesDays: '90 dienų',
    Person: 'Asmuo',
    Expense: 'Išlaidos',
    NewProject: 'Naujas projektas',
    Project: 'Projektas',
    Company: 'Įmonė',
    ResponsiblePerson: 'Atsakingas asmuo',
    ProjectStart: 'Projekto pradžia',
    ProjectEnd: 'Numatoma pabaiga',
    AssignedTo: 'Priskirta',
    ProjectOverview: 'Projekto apžvalga',
    Category: 'Kategorija',
    StageTitle: 'Etapo pavadinimas',
    CancelBtn: 'Atšaukti',
    Invoices: 'Sąskaitos',
    AccountNumber: 'Sąskaitos numeris',
    CompanyName: 'Įmonė',
    AccountType: 'Sąskaitos tipas',
    invoiceIsIssued: 'Saskaitą išrašė',
    DateFrom: 'Pradžios data',
    DateTo: 'Galutinė data'
};
