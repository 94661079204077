import React from 'react';
import { Controller, useForm } from "react-hook-form";
import { useMembers } from "../Users/useUsers";
import FormControl from "@mui/material/FormControl";
import { InputLabelC, InputLabelS, InputSelectField, InputTextField } from "../../Theme/components/InputTextField";
import { __ } from "../../i18n/translation";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { categories } from "../Commons/Options";
const MilestoneForm = ({ milestone, handleClose, mutate }) => {
    const { handleSubmit, control, setValue, formState: { errors } } = useForm({
        defaultValues: {
            milestone_id: 0,
            title: '',
            project_id: '',
            category: '',
            assigned: '',
        },
    });
    const Categories = [
        { value: 1, label: categories[1] },
        { value: 2, label: categories[2] },
        { value: 3, label: categories[3] },
        { value: 4, label: categories[4] },
        { value: 5, label: categories[5] },
        { value: 6, label: categories[6] }
    ];
    const users = useMembers().data;
    const onSubmit = handleSubmit((values) => {
        // mutate(values);
        console.log(values);
    });
    const closeDialog = () => {
        handleClose();
    };
    return (React.createElement("form", { onSubmit: onSubmit },
        React.createElement(FormControl, { fullWidth: true, variant: "outlined", error: errors.title && errors.title.type === "required" },
            React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('StageTitle')),
            React.createElement(Controller, { control: control, defaultValue: '', name: "title", rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.title && errors.title.type === "required", autoComplete: "off" }, field)) }),
            errors.title && errors.title.type === "required" &&
                React.createElement(FormHelperText, null, __('ReqField'))),
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement(FormControl, { fullWidth: true, variant: "outlined", sx: { mr: 1 }, error: errors.category && errors.category.type === "required" },
                React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, __('Category')),
                React.createElement(Controller, { control: control, name: "category", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({}, field), Categories.map((cat) => {
                        return (React.createElement(MenuItem, { key: cat.value, value: cat.value }, cat.label));
                    })) }),
                errors.category && errors.category.type === "required" &&
                    React.createElement(FormHelperText, null, __('ReqField'))),
            React.createElement(FormControl, { fullWidth: true, variant: "outlined", error: errors.assigned && errors.assigned.type === "required" },
                React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, __('AssignedTo')),
                React.createElement(Controller, { control: control, name: "assigned", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({}, field), users === null || users === void 0 ? void 0 : users.map((user, index) => {
                        return (React.createElement(MenuItem, { key: user.user_id, value: user.user_id }, user.name));
                    })) }),
                errors.assigned && errors.assigned.type === "required" &&
                    React.createElement(FormHelperText, null, __('ReqField')))),
        React.createElement(Box, { sx: { mt: 2, display: 'flex', justifyContent: 'space-between' } },
            React.createElement(Button, { color: "primary", onClick: closeDialog },
                " ",
                __('CancelBtn')),
            React.createElement(Button, { type: "submit", variant: "contained", color: "primary" },
                " ",
                __('Save')))));
};
export default MilestoneForm;
