import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { __ } from "../../i18n/translation";
import FormControl from '@mui/material/FormControl';
import { InputTextField, InputLabelC } from "../../Theme/components/InputTextField";
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { usePassword } from "./useUsers";
import SnackBar from "../Commons/Snackbar";
const PasswordForm = () => {
    const { handleSubmit, control, formState: { errors } } = useForm();
    const { mutate, data } = usePassword();
    const onSubmit = handleSubmit((values) => {
        mutate(values);
    });
    return (React.createElement("form", { onSubmit: onSubmit },
        data && React.createElement(SnackBar, { type: 1, msg: __('UpdateSuccess') }),
        React.createElement(Grid, { container: true, spacing: 4, sx: { pr: 4, mt: 1 } },
            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 5 },
                React.createElement(FormControl, { fullWidth: true, variant: "outlined", error: errors.password && errors.password.type === "required" || errors.password && errors.password.type === "minLength" },
                    React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Password')),
                    React.createElement(Controller, { control: control, defaultValue: '', name: "password", rules: { required: true, minLength: 8 }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.password && errors.password.type === "required" || errors.password && errors.password.type === "minLength", 
                            // margin="dense"
                            autoComplete: "off", type: "password" }, field)) }),
                    errors.password && errors.password.type === "required" &&
                        React.createElement(FormHelperText, null, __('ReqField')),
                    errors.password && errors.password.type === "minLength" &&
                        React.createElement(FormHelperText, null, __('MoreNum', { num: 8 }))),
                React.createElement(Box, { sx: { mt: 2 } },
                    React.createElement(Button, { type: "submit", variant: "contained", color: "primary" },
                        " ",
                        __('Save')))))));
};
export default PasswordForm;
