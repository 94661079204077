import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { useParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from "react";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import ProfileForm from "./ProfileForm";
import { UpdateMember, useMember } from "./useUsers";
import ProfileSkeleton from "../Commons/ProfileSkeleton";
import { AppContext } from "../../AppContext";
import SnackBar from "../Commons/Snackbar";
import Alert from "@mui/material/Alert";
const User = () => {
    const { role } = React.useContext(AppContext);
    const { id } = useParams();
    const userId = parseInt(id);
    const { isLoading, data: useUserData, refetch, isSuccess: useUserIsSuccess } = useMember({ userId });
    const [userData, setUser] = useState(useUserData);
    const { mutate, data: useUpdateData, isError, isSuccess: UpdateUserIsSuccess } = UpdateMember();
    useEffect(() => {
        if (UpdateUserIsSuccess && useUpdateData) {
            setUser(useUpdateData);
            refetch();
        }
        if (useUserIsSuccess && useUserData) {
            setUser(useUserData);
        }
    }, [useUpdateData, useUserData, useUserIsSuccess, UpdateUserIsSuccess]);
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { title: __('Member') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Paper, { sx: { pl: 4, pb: 4 } },
                    useUpdateData && React.createElement(SnackBar, { type: 1, msg: __('UpdateSuccess') }),
                    (isError) && React.createElement(Alert, { severity: "error" },
                        "  ",
                        __('emailExist'),
                        " "),
                    isLoading && React.createElement(ProfileSkeleton, null),
                    userData && React.createElement(ProfileForm, { user: userData, type: 1, role: role, mutate: mutate }))))));
};
export default User;
