import React, { Fragment, useCallback, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from "react-dropzone";
import { Zone, ZoneContent, ZoneP, ZoneList, ZoneSingle } from '../../Theme/components/Dropzone';
import { __ } from "../../i18n/translation";
import Alert from "@mui/material/Alert";
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
const FileDropzone = ({ getValues, setValue, files, accept, multiple, formats, size, fileName, img, profile }) => {
    const [error, setError] = useState(false);
    const onDrop = useCallback((acceptedFiles, rejected) => {
        (rejected.length > 0) ? setError(true) : setError(false);
        const old = getValues('files');
        let newValues = acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
        if (old !== undefined) {
            newValues = old.concat(acceptedFiles);
        }
        setValue('files', newValues, { shouldValidate: true });
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept,
        multiple
    });
    // {
    //     'image/jpeg': ['.jpeg', '.jpg'],
    //     'image/png': ['.png'],
    //     'application/pdf': ['.pdf'],
    //     'application/msword': ['.doc'],
    //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    //     'application/vnd.ms-excel': ['.xls'],
    //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    // }
    const removeFile = (index) => {
        let old = getValues('files');
        old.splice(index, 1);
        setValue('files', old, { shouldValidate: true });
    };
    return (React.createElement(Fragment, null,
        ((files === null || files === void 0 ? void 0 : files.length) && !multiple) ? React.createElement(ZoneSingle, null, files.map((file, index) => {
            return (React.createElement(Fragment, { key: file.name },
                profile && React.createElement(Avatar, { alt: "", src: file.preview, sx: { width: 150, height: 150, mb: '20px' }, onLoad: () => {
                        URL.revokeObjectURL(file.preview);
                    } }),
                img && React.createElement("img", { className: "img", src: file.preview, onLoad: () => {
                        URL.revokeObjectURL(file.preview);
                    }, alt: '' }),
                fileName && React.createElement("div", null,
                    file.name,
                    " ",
                    file.size),
                React.createElement(Button, { variant: "contained", onClick: () => removeFile(index), size: "small" }, __('Remove'))));
        })) : React.createElement(Zone, Object.assign({}, getRootProps(), { "aria-label": "File Upload", id: "files" }),
            React.createElement("input", Object.assign({}, getInputProps())),
            React.createElement(ZoneContent, null,
                React.createElement(ZoneP, null,
                    React.createElement(CloudUploadIcon, null)),
                React.createElement(ZoneP, null, __('DragFile')))),
        error ? React.createElement(Alert, { severity: "error" }, __('ErrorFileFormat', { formats, size })) : '',
        !!(files === null || files === void 0 ? void 0 : files.length) && multiple && (React.createElement(ZoneList, null, files.map((file, index) => {
            return (React.createElement("li", { key: file.name },
                img && React.createElement("img", { src: file.preview, onLoad: () => {
                        URL.revokeObjectURL(file.preview);
                    }, alt: '' }),
                React.createElement(Button, { variant: "contained", onClick: () => removeFile(index), size: "small" }, __('Remove')),
                fileName && React.createElement("div", null,
                    file.name,
                    " ",
                    file.size)));
        })))));
};
export default FileDropzone;
