import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles";
import { theme } from './Theme/theme';
import Path from "./Path";
import Cookies from "universal-cookie";
import { decrypt } from "./components/tools";
const cookies = new Cookies();
const isAuth = cookies.get('_isAuth');
const isAllowed = cookies.get('_isAllowed');
import ProtectedRoute from "./components/Commons/ProtectedRoute";
import SessionPopup from "./components/Commons/SessionPopup";
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Clients from './components/Clients/Clients';
import Client from './components/Clients/Client';
import Users from './components/Users/Users';
import User from './components/Users/User';
import Profile from './components/Users/Profile';
import Analytics from "./components/Analytics/Analytics";
import Invoices from "./components/Invoices/Invoices";
import { AppContext } from './AppContext';
import CreateUser from "./components/Users/CreateUser";
import CreateClient from "./components/Clients/CreateClient";
import Expenses from "./components/Expenses/Expenses";
import CreateExpense from "./components/Expenses/CreateExpense";
import CreateProject from "./components/Projects/CreateProject";
import Project from "./components/Projects/Project";
import CreateInvoice from "./components/Invoices/CreateInvoice";
import Invoice from "./components/Invoices/Invoice";
const Allowed = (isAllowed) => {
    if (isAllowed) {
        const { role, time } = (decrypt(isAllowed));
        return { role, time };
    }
    else {
        return {};
    }
};
const App = () => {
    const defaultProtectedRouteProps = {
        isAuthenticated: isAuth,
        authenticationPath: '/login',
    };
    return (React.createElement(Router, null,
        React.createElement(AppContext.Provider, { value: Allowed(isAllowed) },
            React.createElement(ThemeProvider, { theme: theme },
                React.createElement(SessionPopup, null),
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: `${Path.Login}`, component: Login }),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: Path.Home, component: Dashboard })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: Path.Clients, component: Clients })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: `${Path.Clients}/new`, component: CreateClient })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: `${Path.Clients}/:id`, component: Client })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: Path.Users, component: Users })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: `${Path.Users}/new`, component: CreateUser })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: `${Path.Users}/:id`, component: User })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: `${Path.Profile}`, component: Profile })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: Path.Analytics, component: Analytics })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: Path.Bills, component: Invoices })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: `${Path.Bills}/new`, component: CreateInvoice })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: `${Path.Bills}/:id`, component: Invoice })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: Path.Projects, component: Project })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: `${Path.Projects}/new`, component: CreateProject })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: `${Path.Projects}/:id`, component: Project })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: Path.Expenses, component: Expenses })),
                    React.createElement(ProtectedRoute, Object.assign({}, defaultProtectedRouteProps, { isAllowed: isAllowed, restrictedPath: "", exact: true, path: `${Path.Expenses}/new`, component: CreateExpense })))))));
};
export default App;
