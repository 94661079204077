import * as React from "react";
import { Redirect, Route } from 'react-router';
const ProtectedRoute = props => {
    let redirectPath = '';
    if (!props.isAuthenticated) {
        redirectPath = props.authenticationPath;
    }
    if (props.isAuthenticated && !props.isAllowed) {
        redirectPath = props.restrictedPath;
    }
    if (redirectPath) {
        const renderComponent = () => React.createElement(Redirect, { to: { pathname: redirectPath } });
        return React.createElement(Route, Object.assign({}, props, { component: renderComponent, render: undefined }));
    }
    else {
        return React.createElement(Route, Object.assign({}, props));
    }
};
export default ProtectedRoute;
