import React, { useEffect } from 'react';
import { Fragment } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router";
import Path from "../../Path";
import ProjectForm from "./ProjectForm";
import { useCreateProject } from "./useProject";
const CreateProject = () => {
    const { mutate, data, isSuccess } = useCreateProject();
    const history = useHistory();
    const client = { project_id: 0 };
    useEffect(() => {
        if (isSuccess && data) {
            history.push(`${Path.Projects}/${data}`);
        }
    }, [data]);
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { title: __('NewProject') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Paper, { sx: { p: 4 } },
                    React.createElement(ProjectForm, { project: client, mutate: mutate }))))));
};
export default CreateProject;
