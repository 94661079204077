import * as React from "react";
import { useQuery } from "react-query";
import { Fragment, useEffect, useState } from "react";
import Path from "../../Path";
import { __ } from "../../i18n/translation";
import { useFetch } from "../ApiService";
import { checkTime } from "../tools";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppContext } from "../../AppContext";
const fetchReset = () => {
    return useFetch.get(`${Path.ApiResetLogin}`);
};
const SessionPopup = () => {
    const { time } = React.useContext(AppContext);
    const [Time, setTime] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [popup, setPopup] = useState(false);
    const [visible, setVisible] = useState(true);
    const [countNotActive, setNotActive] = useState(0);
    const [countNotClicked, setNotClicked] = useState(0);
    const { refetch } = useQuery(['reset'], fetchReset, {
        enabled: false // disable this query from automatically running
    });
    const screen = () => {
        if (document.hidden) {
            //not visible
            setVisible(false);
        }
        else {
            //is visible
            setVisible(true);
            setNotActive(0);
        }
    };
    const timeCount = () => {
        if (!visible && Time) {
            setNotActive(countNotActive + 1);
        }
        if (Time && visible) {
            setNotClicked(countNotClicked + 1);
        }
        // 30 minute
        if (countNotClicked === 1800) {
            setPopup(true);
            let end = 1860 - countNotClicked;
            if (end === 1) {
                setRedirect(true);
            }
        }
        if (countNotActive > 300) {
            setRedirect(true);
        }
    };
    const checkCookie = () => {
        checkTime(Time) && setRedirect(true);
        if (!popup) {
            setNotClicked(0);
        }
    };
    if (redirect) {
        refetch().then(r => {
            window.location.replace(`${Path.Login}`);
        });
    }
    const Continue = () => {
        setPopup(false);
        setNotClicked(0);
    };
    useEffect(() => {
        setTime(time);
        const interval = setInterval(timeCount, 1000);
        window.addEventListener('visibilitychange', screen);
        window.addEventListener('click', checkCookie);
        return () => {
            clearInterval(interval);
            window.removeEventListener('visibilitychange', screen);
            window.removeEventListener('click', checkCookie);
        };
    }, [countNotActive, countNotClicked, visible, Time]);
    return (React.createElement(Fragment, null,
        React.createElement(Dialog, { open: popup, onClose: Continue, "aria-labelledby": "session-dialog-title", "aria-describedby": "session-dialog-description" },
            React.createElement(DialogTitle, { id: "session-dialog-title" }, "D\u0117mesio!"),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, { id: "session-dialog-description" },
                    __('Continue1'),
                    65 - countNotClicked,
                    __('Continue2'))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: Continue, variant: "contained", color: "primary" },
                    " ",
                    __('Continue'))))));
};
export default SessionPopup;
