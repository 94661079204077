import * as React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { InputLabelC, InputSelectField, InputTextField, DatePickerMui, InputSelectSearch } from "../../Theme/components/InputTextField";
import { __ } from "../../i18n/translation";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { theme } from "../../Theme/theme";
import Paper from "@mui/material/Paper";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import { useClients } from "../Clients/useClients";
const InvoicesForm = ({ invoice, mutate, }) => {
    const today = moment().format("YYYY-MM-DD");
    const dateCreated = (invoice === null || invoice === void 0 ? void 0 : invoice.created) ? moment(invoice === null || invoice === void 0 ? void 0 : invoice.created).format("YYYY-MM-DD") : today;
    const datePaid = (invoice === null || invoice === void 0 ? void 0 : invoice.paid_date) ? moment(invoice === null || invoice === void 0 ? void 0 : invoice.paid_date).format("YYYY-MM-DD") : today;
    // @TODO change dayJS on moment
    const [dateFrom, setDateFrom] = React.useState(dayjs(dateCreated));
    const [dateTo, setDateTo] = React.useState(dayjs(datePaid));
    console.log(dateCreated);
    const { register, control, handleSubmit, watch, setValue, getValues, formState: { errors, touchedFields } } = useForm({
        defaultValues: {
            typeAccount: (invoice === null || invoice === void 0 ? void 0 : invoice.typeAccount) ? invoice.typeAccount : 1,
            companyName: (invoice === null || invoice === void 0 ? void 0 : invoice.companyName) ? invoice.companyName : '',
            discount: (invoice === null || invoice === void 0 ? void 0 : invoice.discount) ? invoice.discount : 0,
            paid_date: (invoice === null || invoice === void 0 ? void 0 : invoice.paid_date) ? invoice.paid_date : `${today}`,
            sum: (invoice === null || invoice === void 0 ? void 0 : invoice.sum) ? invoice.sum : 0,
            client_id: (invoice === null || invoice === void 0 ? void 0 : invoice.client_id) ? invoice.client_id : 0,
            created: (invoice === null || invoice === void 0 ? void 0 : invoice.created) ? dateCreated : `${today}`,
            discountValue: (invoice === null || invoice === void 0 ? void 0 : invoice.discountValue) ? invoice.discountValue : 0,
            invoice_number: (invoice === null || invoice === void 0 ? void 0 : invoice.invoice_number) ? invoice.invoice_number : '',
            vat: (invoice === null || invoice === void 0 ? void 0 : invoice.vat) ? invoice.vat : 0,
            vatPercent: (invoice === null || invoice === void 0 ? void 0 : invoice.vatPercent) ? invoice.vatPercent : 21,
            total: (invoice === null || invoice === void 0 ? void 0 : invoice.total) ? invoice.total : 0,
            invoiceIsIssued: (invoice === null || invoice === void 0 ? void 0 : invoice.invoiceIsIssued) ? invoice.invoiceIsIssued : '',
            InvoiceFields: (invoice === null || invoice === void 0 ? void 0 : invoice.InvoiceFields) ? invoice === null || invoice === void 0 ? void 0 : invoice.InvoiceFields : [{
                    counter: 'Val',
                    name: '',
                    quantity: '',
                    price: '',
                    sum: 0,
                }],
        },
    });
    // console.log(invoice)
    const counter = () => {
        const sum = Number(getValues("sum"));
        const discount = Number(getValues("discount"));
        const vatPercent = getValues("vatPercent");
        let resultDiscount = (discount > 0) ? (((Number(sum) / 100) * discount)) : 0;
        setValue('discountValue', resultDiscount.toFixed(2));
        let resultVat = (vatPercent > 0) ? (((sum - resultDiscount) / 100) * vatPercent) : 0;
        setValue('vat', resultVat.toFixed(2));
        let resultAllSum = sum - resultDiscount + resultVat;
        setValue('total', resultAllSum.toFixed(2));
    };
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            var _a, _b, _c, _d;
            let totalUp = 0;
            if (type === 'change') {
                if (name) {
                    const cartIndexName = name.split('.');
                    if (cartIndexName[2] === 'price' || cartIndexName[2] === 'quantity') {
                        const index = parseInt(cartIndexName[1]);
                        if (value.InvoiceFields && typeof value.InvoiceFields[index] !== 'undefined') {
                            const price = ((_a = value.InvoiceFields[index]) === null || _a === void 0 ? void 0 : _a.price) || 0;
                            const quantity = ((_b = value.InvoiceFields[index]) === null || _b === void 0 ? void 0 : _b.quantity) || 0;
                            const result = price * quantity;
                            setValue(`InvoiceFields.${cartIndexName[1]}.sum`, result);
                        }
                        counter();
                    }
                    const index = parseInt(cartIndexName[1]);
                    if (value.InvoiceFields && typeof value.InvoiceFields[index] !== 'undefined') {
                        const price = ((_c = value.InvoiceFields[index]) === null || _c === void 0 ? void 0 : _c.price) || 0;
                        const quantity = ((_d = value.InvoiceFields[index]) === null || _d === void 0 ? void 0 : _d.quantity) || 0;
                        const result = price * quantity;
                        if (value === null || value === void 0 ? void 0 : value.InvoiceFields) {
                            value === null || value === void 0 ? void 0 : value.InvoiceFields.map((InvoiceFields) => {
                                if (InvoiceFields === null || InvoiceFields === void 0 ? void 0 : InvoiceFields.sum) {
                                    totalUp += InvoiceFields === null || InvoiceFields === void 0 ? void 0 : InvoiceFields.sum;
                                    setValue('sum', totalUp.toFixed(2));
                                    counter();
                                }
                            });
                        }
                    }
                }
            }
            if (name === 'discount') {
                counter();
            }
            if (name === 'vatPercent') {
                counter();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    const { fields, append, remove } = useFieldArray({
        name: "InvoiceFields",
        control,
    });
    const { isLoading, isError, data: Clients, error: ErroClent, refetch } = useClients({});
    const [modal, setModal] = useState({ open: false, client: { client_id: 0, company_name: '' } });
    const RemoveContact = (client) => {
        setModal({
            open: true,
            client: {
                client_id: client.client_id,
                company_name: client.company_name
            }
        });
    };
    const onSubmit = handleSubmit((values) => {
        mutate(values);
    });
    return (React.createElement("form", { onSubmit: onSubmit },
        React.createElement(Grid, { item: true, xs: 4, sm: 4, md: 4 },
            React.createElement(Grid, { container: true, spacing: 2, style: { display: 'flex' } },
                React.createElement(Grid, { item: true, xs: 11 },
                    React.createElement(Box, { sx: { display: 'flex' } },
                        React.createElement(Box, { sx: {
                                flexGrow: 1,
                                mb: 1,
                                px: 0,
                                py: 1,
                            } },
                            React.createElement(Grid, { container: true, spacing: 2, sx: { alignItems: "center" } },
                                React.createElement(Grid, { item: true, xs: 5 },
                                    React.createElement(FormControl, { size: "small", fullWidth: true, error: errors.companyName && errors.companyName.type === "required" },
                                        React.createElement(Controller, { control: control, name: "companyName", render: ({ field }) => (React.createElement(Autocomplete, { disablePortal: true, options: Clients || [], defaultValue: (invoice === null || invoice === void 0 ? void 0 : invoice.companyName) ? { company_name: invoice === null || invoice === void 0 ? void 0 : invoice.companyName } : null, getOptionLabel: (option) => option.company_name || '', isOptionEqualToValue: (option, value) => option.company_name === value.company_name, fullWidth: true, onChange: (event, value) => {
                                                    value && setValue('companyName', value.company_name);
                                                    value && setValue('client_id', value.client_id);
                                                }, renderInput: (params) => React.createElement(InputSelectSearch, Object.assign({}, params)) })) }),
                                        errors.companyName && errors.companyName.type === "required" &&
                                            React.createElement(FormHelperText, null, __('ReqField'))),
                                    React.createElement(Grid, { sx: { display: 'flex', gap: 2 } },
                                        React.createElement(Grid, { item: true, xs: 6 },
                                            React.createElement(FormControl, { variant: "outlined", fullWidth: true, error: errors.invoice_number && errors.invoice_number.type === "required" },
                                                React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('AccountNumber')),
                                                React.createElement(Controller, { control: control, name: "invoice_number", rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.invoice_number && errors.invoice_number.type === "required", margin: "dense", fullWidth: true, autoComplete: "on" }, field)) }),
                                                errors.invoice_number && errors.invoice_number.type === "required" &&
                                                    React.createElement(FormHelperText, null, __('ReqField')))),
                                        React.createElement(Grid, { item: true, xs: 6 },
                                            React.createElement(FormControl, { size: "small", fullWidth: true },
                                                React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('AccountType')),
                                                React.createElement(Controller, { control: control, name: "typeAccount", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({ fullWidth: true }, field),
                                                        React.createElement(MenuItem, { value: 1 }, "Standartin\u0117"),
                                                        React.createElement(MenuItem, { value: 2 }, "I\u0161ansktin\u0117")) })))),
                                    React.createElement(Grid, { sx: { display: 'flex', gap: 2, paddingTop: 2 } },
                                        React.createElement(Grid, { item: true, xs: 6 },
                                            React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                                                React.createElement(DatePickerMui, Object.assign({}, register('created', {
                                                    required: true
                                                }), { value: dateFrom, onChange: (newValue) => {
                                                        var _a;
                                                        setDateFrom(newValue);
                                                        if (newValue) {
                                                            setValue('created', (_a = newValue.format) === null || _a === void 0 ? void 0 : _a.call(newValue, "YYYY-MM-DD"), {
                                                                shouldValidate: true,
                                                                shouldDirty: true
                                                            });
                                                        }
                                                    }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params)) })))),
                                        React.createElement(Grid, { item: true, xs: 6 },
                                            React.createElement(FormControl, { size: "small", fullWidth: true, error: errors.paid_date && errors.paid_date.type === "required" },
                                                React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                                                    React.createElement(DatePickerMui, Object.assign({}, register('paid_date', {
                                                        required: true,
                                                    }), { value: dateTo, onChange: (date) => {
                                                            var _a;
                                                            setDateTo(date);
                                                            if (date) {
                                                                setValue('paid_date', (_a = date.format) === null || _a === void 0 ? void 0 : _a.call(date, "YYYY-MM-DD"), {
                                                                    shouldValidate: true,
                                                                    shouldDirty: true
                                                                });
                                                            }
                                                        }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { error: errors.paid_date && errors.paid_date.type === "required" })) }))),
                                                errors.paid_date && errors.paid_date.type === "required" &&
                                                    React.createElement(FormHelperText, null, __('ReqField'))))))))))),
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 11 },
                    React.createElement(Paper, null,
                        React.createElement(Box, { sx: { display: 'flex', backgroundColor: `${theme.palette.grey[100]}`, color: `${theme.palette.grey[900]}`, my: 1 } },
                            React.createElement(Box, { component: "main", sx: {
                                    flexGrow: 1,
                                    px: 2,
                                    py: 1,
                                    borderBottom: `solid 1px ${theme.palette.grey[200]}`,
                                    borderTop: `solid 1px ${theme.palette.grey[200]}`
                                } },
                                React.createElement(Grid, { container: true, spacing: 2, sx: { alignItems: "center" } },
                                    React.createElement(Grid, { item: true, xs: 5 }, "Pavadinimas"),
                                    React.createElement(Grid, { item: true, xs: 1 }, "Mat.vnt"),
                                    React.createElement(Grid, { item: true, xs: 2 }, "Kiekis"),
                                    React.createElement(Grid, { item: true, xs: 2 }, "Kaina"),
                                    React.createElement(Grid, { item: true, xs: 2 }, "Viso"))))))),
            fields.map((field, index) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
                return (React.createElement("div", { key: field.id },
                    React.createElement("section", { className: "section", key: field.id, style: { marginTop: 8 } },
                        React.createElement(Grid, { container: true, spacing: 2, style: { display: 'flex' } },
                            React.createElement(Grid, { item: true, xs: 11 },
                                React.createElement(Paper, null,
                                    React.createElement(Box, { sx: { display: 'flex' } },
                                        React.createElement(Box, { component: "main", sx: {
                                                flexGrow: 1,
                                                mb: 2,
                                                px: 0,
                                                py: 1,
                                            } },
                                            React.createElement(Grid, { container: true, spacing: 2, sx: { alignItems: "center" } },
                                                React.createElement(Grid, { item: true, xs: 5 },
                                                    React.createElement(FormControl, { variant: "outlined", fullWidth: true, error: (errors === null || errors === void 0 ? void 0 : errors.InvoiceFields) && ((_a = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _a === void 0 ? void 0 : _a.name) &&
                                                            ((_c = (_b = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.type) === "required" },
                                                        React.createElement(Controller, { control: control, name: `InvoiceFields.${index}.name`, rules: { required: true }, render: ({ field }) => {
                                                                var _a, _b, _c;
                                                                return React.createElement(InputTextField, Object.assign({ error: (errors === null || errors === void 0 ? void 0 : errors.InvoiceFields) && ((_a = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _a === void 0 ? void 0 : _a.name) &&
                                                                        ((_c = (_b = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.type) === "required", margin: "dense", autoComplete: "off", fullWidth: true }, field));
                                                            } }),
                                                        (errors === null || errors === void 0 ? void 0 : errors.InvoiceFields) && ((_d = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _d === void 0 ? void 0 : _d.name) &&
                                                            ((_f = (_e = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f.type) === "required" &&
                                                            React.createElement(FormHelperText, { style: { marginRight: 0, width: '100%', marginBottom: -23 } }, __('ReqField')))),
                                                React.createElement(Grid, { item: true, xs: 1 },
                                                    React.createElement(FormControl, { size: "small" },
                                                        React.createElement(Controller, { control: control, name: `InvoiceFields.${index}.counter`, rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({ style: { marginTop: 0 }, fullWidth: true }, field),
                                                                React.createElement(MenuItem, { value: "Val" }, "Val"),
                                                                React.createElement(MenuItem, { value: "Vnt" }, "Vnt")) }))),
                                                React.createElement(Grid, { item: true, xs: 2 },
                                                    React.createElement(FormControl, { variant: "outlined", error: (errors === null || errors === void 0 ? void 0 : errors.InvoiceFields) && ((_g = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _g === void 0 ? void 0 : _g.quantity) &&
                                                            ((_j = (_h = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _h === void 0 ? void 0 : _h.quantity) === null || _j === void 0 ? void 0 : _j.type) === "required" },
                                                        React.createElement(Controller, { control: control, name: `InvoiceFields.${index}.quantity`, rules: {
                                                                required: { value: true, message: "Required" },
                                                                min: {
                                                                    value: 0.01,
                                                                    message: "Min number"
                                                                }
                                                            }, render: ({ field }) => {
                                                                var _a, _b, _c;
                                                                return React.createElement(InputTextField, Object.assign({ error: (errors === null || errors === void 0 ? void 0 : errors.InvoiceFields) && ((_a = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _a === void 0 ? void 0 : _a.quantity) &&
                                                                        ((_c = (_b = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _b === void 0 ? void 0 : _b.quantity) === null || _c === void 0 ? void 0 : _c.type) === "required", margin: "dense", 
                                                                    // style={{ marginLeft: 25 }}
                                                                    autoComplete: "off", type: "number", fullWidth: true }, field));
                                                            } }),
                                                        (errors === null || errors === void 0 ? void 0 : errors.InvoiceFields) && ((_k = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _k === void 0 ? void 0 : _k.quantity) &&
                                                            ((_m = (_l = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _l === void 0 ? void 0 : _l.quantity) === null || _m === void 0 ? void 0 : _m.type) === "required" &&
                                                            React.createElement(FormHelperText, { style: { marginRight: 0, width: '100%', marginBottom: -23 } }, __('ReqField')),
                                                        (errors === null || errors === void 0 ? void 0 : errors.InvoiceFields) && ((_o = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _o === void 0 ? void 0 : _o.quantity) &&
                                                            ((_q = (_p = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _p === void 0 ? void 0 : _p.quantity) === null || _q === void 0 ? void 0 : _q.type) === "min" &&
                                                            React.createElement(FormHelperText, { style: { marginRight: 0, width: '100%', marginBottom: -23 } }, __('MinAmount')))),
                                                React.createElement(Grid, { item: true, xs: 2 },
                                                    React.createElement(FormControl, { variant: "outlined", error: (errors === null || errors === void 0 ? void 0 : errors.InvoiceFields) && ((_r = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _r === void 0 ? void 0 : _r.price) &&
                                                            ((_t = (_s = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _s === void 0 ? void 0 : _s.price) === null || _t === void 0 ? void 0 : _t.type) === "required" },
                                                        React.createElement(Controller, { control: control, name: `InvoiceFields.${index}.price`, rules: {
                                                                required: { value: true, message: "Required" },
                                                                min: {
                                                                    value: 0.01,
                                                                    message: "Min number"
                                                                }
                                                            }, render: ({ field }) => {
                                                                var _a, _b, _c;
                                                                return React.createElement(InputTextField, Object.assign({ error: (errors === null || errors === void 0 ? void 0 : errors.InvoiceFields) && ((_a = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _a === void 0 ? void 0 : _a.price) &&
                                                                        ((_c = (_b = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _b === void 0 ? void 0 : _b.price) === null || _c === void 0 ? void 0 : _c.type) === "required", margin: "dense", autoComplete: "off", type: "number", fullWidth: true }, field));
                                                            } })),
                                                    (errors === null || errors === void 0 ? void 0 : errors.InvoiceFields) && ((_u = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _u === void 0 ? void 0 : _u.price) &&
                                                        ((_w = (_v = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _v === void 0 ? void 0 : _v.price) === null || _w === void 0 ? void 0 : _w.type) === "required" &&
                                                        React.createElement(FormHelperText, { style: { marginRight: 0, width: '100%', marginBottom: -23 } }, __('ReqField')),
                                                    (errors === null || errors === void 0 ? void 0 : errors.InvoiceFields) && ((_x = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _x === void 0 ? void 0 : _x.price) &&
                                                        ((_z = (_y = errors === null || errors === void 0 ? void 0 : errors.InvoiceFields[index]) === null || _y === void 0 ? void 0 : _y.price) === null || _z === void 0 ? void 0 : _z.type) === "min" &&
                                                        React.createElement(FormHelperText, { style: { marginRight: 0, width: '100%', marginBottom: -23 } }, __('MinField'))),
                                                React.createElement(Grid, { item: true, xs: 2 },
                                                    React.createElement(FormControl, { variant: "outlined", error: `errors.InvoiceFields.${index}.sum` && `errors.InvoiceFields.${index}.sum.type` === "required" },
                                                        React.createElement(Controller, { control: control, name: `InvoiceFields.${index}.sum`, rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: `errors.InvoiceFields.${index}.sum` && `errors.InvoiceFields.${index}.sum.type` === "required", margin: "dense", disabled: true, autoComplete: "off", type: "number", fullWidth: true }, field)) })))))))),
                            React.createElement(Button, { type: "button", onClick: () => remove(index) },
                                React.createElement(DeleteIcon, null))))));
            }),
            React.createElement("div", { className: "append" },
                React.createElement(Button, { variant: "contained", style: { marginTop: 10, marginBottom: 15 }, onClick: () => append({
                        sum: 0,
                        counter: "Val",
                        name: "",
                        quantity: '',
                        price: '',
                    }) }, "Prideti lauk\u0105")),
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 11 },
                    React.createElement(Paper, null,
                        React.createElement(Box, { sx: { display: 'block' } },
                            React.createElement(Box, { component: "main", sx: {
                                    py: 1,
                                } },
                                React.createElement(Grid, { container: true, sx: { alignItems: "center", display: 'flex' } },
                                    React.createElement(Grid, { item: true, xs: 6 }),
                                    React.createElement(Grid, { item: true, xs: 6 },
                                        React.createElement(Grid, { item: true, sx: { display: 'flex' } },
                                            React.createElement(Grid, { item: true, xs: 8 },
                                                React.createElement("p", null, "Viso")),
                                            React.createElement(FormControl, { variant: "outlined", error: errors.sum && errors.sum.type === "required" },
                                                React.createElement(Controller, { control: control, name: "sum", rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ disabled: true, error: errors.sum && errors.sum.type === "required", margin: "dense", autoComplete: "off" }, field)) }))),
                                        React.createElement(Grid, { item: true, sx: { display: 'flex' } },
                                            React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement("p", null, "Nuolaida")),
                                            React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(FormControl, { size: "small" },
                                                    React.createElement(Controller, { control: control, name: "discount", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({ style: { marginTop: 0, width: 100 }, onClose: (e) => {
                                                            } }, field),
                                                            React.createElement(MenuItem, { value: '0' }, "0%"),
                                                            React.createElement(MenuItem, { value: '10' }, "10%"),
                                                            React.createElement(MenuItem, { value: '15' }, "15%"),
                                                            React.createElement(MenuItem, { value: '25' }, "25%")) }))),
                                            React.createElement(FormControl, { variant: "outlined", error: errors.discountValue && errors.discountValue.type === "required" },
                                                React.createElement(Controller, { control: control, name: "discountValue", rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ disabled: true, error: errors.discountValue && errors.discountValue.type === "required", margin: "dense", autoComplete: "off" }, field)) }))),
                                        React.createElement(Grid, { item: true, sx: { display: 'flex' } },
                                            React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement("p", null, "VAT")),
                                            React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(FormControl, { size: "small" },
                                                    React.createElement(Controller, { control: control, name: "vatPercent", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({ style: { marginTop: 0, width: 100 }, onClose: (e) => {
                                                            } }, field),
                                                            React.createElement(MenuItem, { value: '0' }, "0%"),
                                                            React.createElement(MenuItem, { value: '5' }, "5%"),
                                                            React.createElement(MenuItem, { value: '9' }, "9%"),
                                                            React.createElement(MenuItem, { value: '21' }, "21%")) }))),
                                            React.createElement(FormControl, { variant: "outlined", error: errors.vat && errors.vat.type === "required" },
                                                React.createElement(Controller, { control: control, name: "vat", rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ disabled: true, error: errors.vat && errors.vat.type === "required", margin: "dense", autoComplete: "off" }, field)) }))),
                                        React.createElement(Grid, { item: true, sx: { display: 'flex' } },
                                            React.createElement(Grid, { item: true, xs: 8 },
                                                React.createElement("p", { style: { width: 100 } }, "Suma")),
                                            React.createElement(FormControl, { variant: "outlined", error: errors.total && errors.total.type === "required" },
                                                React.createElement(Controller, { control: control, name: "total", rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ disabled: true, error: errors.total && errors.total.type === "required", margin: "dense", autoComplete: "off" }, field)) })))),
                                    React.createElement(FormControl, { variant: "outlined", error: errors.invoiceIsIssued && errors.invoiceIsIssued.type === "required", fullWidth: true },
                                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('invoiceIsIssued')),
                                        React.createElement(Controller, { control: control, name: "invoiceIsIssued", rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.invoiceIsIssued && errors.invoiceIsIssued.type === "required", margin: "dense", fullWidth: true, autoComplete: "off" }, field)) }),
                                        errors.invoiceIsIssued && errors.invoiceIsIssued.type === "required" &&
                                            React.createElement(FormHelperText, null, __('ReqField')))))),
                        React.createElement("div", { style: { padding: 30, float: "right" } },
                            React.createElement(Button, { type: "submit", variant: "contained", color: "primary" },
                                " ",
                                __('Save')))))))));
};
export default InvoicesForm;
