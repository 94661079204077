import * as React from "react";
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";
import Path from "../../Path";
import { __ } from "../../i18n/translation";
import { Brand, SideBarLink } from '../../Theme/components/SideBar';
import logo from '../../media/logo.svg';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import ListItemText from "@mui/material/ListItemText";
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import InsightsIcon from '@mui/icons-material/Insights';
import Groups2Icon from '@mui/icons-material/Groups2';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import TableViewIcon from '@mui/icons-material/TableView';
import Button from '@mui/material/Button';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Box from '@mui/material/Box';
import MoreIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import { DefLinkNoSize } from "../../Theme/components/Commons";
import { AppBar, Drawer } from "../../Theme/components/MuiSitebar";
import { useTheme } from "@mui/material/styles";
import { AppContext } from "../../AppContext";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssignmentIcon from '@mui/icons-material/Assignment';
const SideBar = ({ btnType, title }) => {
    const { role } = React.useContext(AppContext);
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleMenuLogout = () => {
        handleMobileMenuClose();
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const menuId = 'primary-search-account-menu';
    const renderMenu = (React.createElement(Menu, { anchorEl: anchorEl, anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, id: menuId, keepMounted: true, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, open: isMenuOpen, onClose: handleMenuClose },
        React.createElement(MenuItem, { onClick: handleMenuClose },
            React.createElement(DefLinkNoSize, { to: Path.Profile }, "Profile")),
        React.createElement(MenuItem, { onClick: handleMenuLogout },
            React.createElement(DefLinkNoSize, { to: "#" }, "Logout"))));
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (React.createElement(Menu, { anchorEl: mobileMoreAnchorEl, anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, id: mobileMenuId, keepMounted: true, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, open: isMobileMenuOpen, onClose: handleMobileMenuClose },
        React.createElement(MenuItem, null,
            React.createElement(IconButton, { size: "large", "aria-label": "show 4 new mails", color: "inherit" },
                React.createElement(Badge, { badgeContent: 4, color: "error" },
                    React.createElement(MailIcon, { sx: { color: theme.palette.grey[700] } }))),
            React.createElement("p", null, "Messages")),
        React.createElement(MenuItem, null,
            React.createElement(IconButton, { size: "large", "aria-label": "show 17 new notifications", color: "inherit" },
                React.createElement(Badge, { badgeContent: 17, color: "error" },
                    React.createElement(NotificationsIcon, { sx: { color: theme.palette.grey[700] } }))),
            React.createElement("p", null, "Notifications")),
        React.createElement(MenuItem, { onClick: handleProfileMenuOpen },
            React.createElement(IconButton, { size: "large", "aria-label": "account of current user", "aria-controls": "primary-search-account-menu", "aria-haspopup": "true" },
                React.createElement(Avatar, { src: "/static/images/avatar/2.jpg", sx: { backgroundColor: theme.palette.grey[700] } })),
            React.createElement("p", null, "Profile"))));
    return (React.createElement(Fragment, null,
        React.createElement(AppBar, { position: "fixed", open: open, sx: { backgroundColor: 'white', color: "#273142", boxShadow: 1 } },
            React.createElement(Toolbar, null,
                React.createElement(IconButton, { color: "inherit", "aria-label": "open drawer", onClick: handleDrawerOpen, edge: "start", sx: Object.assign({ marginRight: 5 }, (open && { display: 'none' })) },
                    React.createElement(MenuIcon, null)),
                React.createElement(Typography, { variant: "h1", noWrap: true, component: "div", sx: { fontWeight: 500, fontSize: '1.5rem', mr: 4 } },
                    "Daisoras",
                    title && React.createElement(Fragment, null,
                        ": ",
                        title)),
                btnType === 1 && role === 1 &&
                    React.createElement(Button, { variant: "contained", endIcon: React.createElement(PersonAddAlt1Icon, null), color: "success", sx: { color: 'white' }, href: `${Path.Users}/new` }, "Prideti nar\u012F"),
                btnType === 2 && role === 1 &&
                    React.createElement(Button, { variant: "contained", endIcon: React.createElement(PersonAddAlt1Icon, null), color: "success", sx: { color: 'white' }, href: `${Path.Clients}/new` }, "Prideti klient\u0105"),
                btnType === 3 &&
                    React.createElement(Button, { variant: "contained", endIcon: React.createElement(PersonAddAlt1Icon, null), color: "success", sx: { color: 'white' }, href: `${Path.Bills}/new` }, "Prideti s\u0105skait\u0105"),
                btnType === 4 && role === 1 &&
                    React.createElement(Button, { variant: "contained", endIcon: React.createElement(ReceiptIcon, null), color: "success", sx: { color: 'white' }, href: `${Path.Expenses}/new` }, "Prideti I\u0161laidas"),
                btnType === 5 && role === 1 &&
                    React.createElement(Button, { variant: "contained", endIcon: React.createElement(AssignmentIcon, null), color: "success", sx: { color: 'white' }, href: `${Path.Projects}/new` }, "Prideti Projekt\u0105"),
                React.createElement(Box, { sx: { flexGrow: 1 } }),
                React.createElement(Box, { sx: { display: { xs: 'none', md: 'flex' } } },
                    React.createElement(IconButton, { size: "large", "aria-label": "show 4 new mails", color: "inherit" },
                        React.createElement(Badge, { badgeContent: 4, color: "error" },
                            React.createElement(MailIcon, { sx: { color: theme.palette.grey[700] } }))),
                    React.createElement(IconButton, { size: "large", "aria-label": "show 17 new notifications", color: "inherit" },
                        React.createElement(Badge, { badgeContent: 17, color: "error" },
                            React.createElement(NotificationsIcon, { sx: { color: theme.palette.grey[700] } }))),
                    React.createElement(IconButton, { size: "large", edge: "end", "aria-label": "account of current user", "aria-controls": menuId, "aria-haspopup": "true", onClick: handleProfileMenuOpen },
                        React.createElement(Avatar, { src: "/static/images/avatar/2.jpg", sx: { backgroundColor: theme.palette.grey[700], width: '30px', height: '30px' } }))),
                React.createElement(Box, { sx: { display: { xs: 'flex', md: 'none' } } },
                    React.createElement(IconButton, { size: "large", "aria-label": "show more", "aria-controls": mobileMenuId, "aria-haspopup": "true", onClick: handleMobileMenuOpen, color: "inherit" },
                        React.createElement(MoreIcon, null))))),
        renderMobileMenu,
        renderMenu,
        React.createElement(Drawer, { variant: "permanent", open: open },
            React.createElement(DrawerHeader, null,
                React.createElement(Brand, null,
                    React.createElement(Link, { to: Path.Home },
                        React.createElement("img", { className: "brand-img", src: logo, alt: "Logo" }))),
                React.createElement(IconButton, { onClick: handleDrawerClose }, theme.direction === 'rtl' ? React.createElement(ChevronRightIcon, null) : React.createElement(ChevronLeftIcon, null))),
            React.createElement(Divider, null),
            React.createElement(List, null,
                React.createElement(ListItem, { key: __('Overview'), disablePadding: true, sx: { display: 'block' } },
                    React.createElement(ListItemButton, { exact: true, component: SideBarLink, to: Path.Home, sx: { minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, } },
                        React.createElement(ListItemIcon, { sx: { minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', } },
                            React.createElement(HomeIcon, null)),
                        React.createElement(ListItemText, { primary: __('Overview'), sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(ListItem, { key: __('Analytics'), disablePadding: true, sx: { display: 'block' } },
                    React.createElement(ListItemButton, { exact: true, component: SideBarLink, to: Path.Analytics, sx: { minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, } },
                        React.createElement(ListItemIcon, { sx: { minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', } },
                            React.createElement(InsightsIcon, null)),
                        React.createElement(ListItemText, { primary: __('Analytics'), sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(ListItem, { key: __('Clients'), disablePadding: true, sx: { display: 'block' } },
                    React.createElement(ListItemButton, { exact: true, component: SideBarLink, to: Path.Clients, sx: { minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, } },
                        React.createElement(ListItemIcon, { sx: { minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', } },
                            React.createElement(Groups2Icon, null)),
                        React.createElement(ListItemText, { primary: __('Clients'), sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(ListItem, { key: __('Expense'), disablePadding: true, sx: { display: 'block' } },
                    React.createElement(ListItemButton, { exact: true, component: SideBarLink, to: Path.Expenses, sx: { minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, } },
                        React.createElement(ListItemIcon, { sx: { minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', } },
                            React.createElement(AccountBalanceWalletIcon, null)),
                        React.createElement(ListItemText, { primary: __('Expense'), sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(ListItem, { key: __('Bills'), disablePadding: true, sx: { display: 'block' } },
                    React.createElement(ListItemButton, { exact: true, component: SideBarLink, to: Path.Bills, sx: { minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, } },
                        React.createElement(ListItemIcon, { sx: { minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', } },
                            React.createElement(FileCopyIcon, null)),
                        React.createElement(ListItemText, { primary: __('Bills'), sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(ListItem, { key: __('Members'), disablePadding: true, sx: { display: 'block' } },
                    React.createElement(ListItemButton, { exact: true, component: SideBarLink, to: Path.Users, sx: { minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, } },
                        React.createElement(ListItemIcon, { sx: { minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', } },
                            React.createElement(RecentActorsIcon, null)),
                        React.createElement(ListItemText, { primary: __('Members'), sx: { opacity: open ? 1 : 0 } }))),
                React.createElement(ListItem, { key: __('Projects'), disablePadding: true, sx: { display: 'block' } },
                    React.createElement(ListItemButton, { exact: true, component: SideBarLink, to: Path.Projects, sx: { minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, } },
                        React.createElement(ListItemIcon, { sx: { minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', } },
                            React.createElement(TableViewIcon, null)),
                        React.createElement(ListItemText, { primary: __('Projects'), sx: { opacity: open ? 1 : 0 } })))))));
};
export default SideBar;
