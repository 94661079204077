import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
export const InputTextField = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(4),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.primary.main,
        border: `1px solid ${theme.palette.grey[300]}`,
        fontSize: theme.typography.fontSize,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
        '&:focus': {
            borderColor: theme.palette.secondary.main
        },
    },
    '&.Mui-error': {
        '& .MuiInputBase-input': {
            border: `1px solid ${theme.palette.error.main}`,
            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
        },
        '& .MuiFormHelperText-root': {
            color: theme.palette.error.main
        }
    }
}));
export const InputLabelC = styled(InputLabel)(({ theme }) => ({
    marginTop: theme.spacing(2),
    color: theme.palette.grey[700],
    transform: 'translate(0, -9px) scale(0.75)',
    fontSize: '1.2rem',
    fontWeight: theme.typography.fontWeightMedium
}));
export const InputSelectField = styled(Select)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(2),
    },
    '& .MuiSelect-nativeInput': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.primary.main,
        border: `1px solid ${theme.palette.grey[300]}`,
        fontSize: theme.typography.fontSize,
    },
    '&.MuiOutlinedInput-root': {
        marginTop: '32px',
        '& .MuiOutlinedInput-input': {
            padding: '7.5px 14px',
        },
    },
    '&.MuiInputBase-root': {
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main
            }
        },
    },
    '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: `${theme.palette.grey[300]}`,
        },
    },
    '&.Mui-error': {
        '& .MuiInputBase-input': {
            border: `1px solid ${theme.palette.error.main}`,
            '&:focus': {
                borderColor: theme.palette.error.main,
            },
        },
        '& .MuiFormHelperText-root': {
            color: theme.palette.error.main
        }
    }
}));
export const InputSelectSearch = styled(TextField)(({ theme }) => ({
    '& input': {
        height: '8px',
    },
    '& .MuiOutlinedInput-root': {
        padding: '8px'
    },
    "& .Mui-focused": {
        border: `1px solid ${theme.palette.primary.main}`,
        'input': {
            margin: '-1px',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        }
    },
    '& .Mui-error': {
        '& .MuiInputBase-input': {
            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
        },
        '& .MuiFormHelperText-root': {
            color: theme.palette.error.main
        }
    }
}));
export const InputLabelS = styled(InputLabel)(({ theme }) => ({
    top: '16px',
    color: theme.palette.grey[700],
    transform: 'translate(0, -9px) scale(0.75)',
    fontSize: '1.2rem',
    fontWeight: theme.typography.fontWeightMedium,
    // '&.Mui-focused':{
    //     color: theme.palette.grey[700],
    // },
}));
// @TODO sumerginti datapickerius
export const DatePickerField = styled(DatePicker)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(4),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        // backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.primary.main,
        // border: `1px solid ${theme.palette.grey[300]}`,
        fontSize: '15px',
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
        '&:focus': {
            borderColor: theme.palette.secondary.main
        },
    },
    '&.Mui-error': {
        '& .MuiInputBase-input': {
            border: `1px solid ${theme.palette.error.main}`,
            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
        },
        '& .MuiFormHelperText-root': {
            color: theme.palette.error.main
        }
    }
}));
// export const InputLabelC = styled(InputLabel)(({theme}) => ({
//     marginTop: theme.spacing(2),
//     color: theme.palette.grey[700],
//     transform: 'translate(0, -9px) scale(0.75)',
//     fontSize: '1.2rem',
//     fontWeight: theme.typography.fontWeightMedium
// }));
export const DatePickerMui = styled(DatePicker)(({ theme }) => ({
    borderRadius: 4,
    position: 'relative',
    marginTop: '30px',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.primary.main,
    transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
    ]),
    '&:hover': {
        borderColor: theme.palette.primary.main,
        borderWidth: '0'
    },
    '&:focus': {
        borderColor: theme.palette.secondary.main,
        borderWidth: '0'
    },
    '& .MuiOutlinedInput-root': {
        height: 39,
    },
    "& .Mui-focused": {
        border: `1px solid ${theme.palette.primary.main}`,
        'input': {
            margin: '0 -1px 0 -1px',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        }
    },
    "& .Mui-error": {
        border: `1px solid ${theme.palette.error.main}`,
        'input': {
            margin: '0 -1px'
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        }
    }
}));
