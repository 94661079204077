import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { theme } from '../theme';
import { purple } from '@mui/material/colors';
export const Brand = styled.div `
  a {
    display: block;
    width: 100%;
    text-align: center;
    
    img {
      display: inline-block; 
      height: 44px;
      margin-right: 37px;
    }
  }
`;
const activeClassName = 'is-active';
export const SideBarLink = styled(NavLink).attrs({ activeClassName }) `
  display: flex;
  align-content: center;
  align-items: center;
  padding: 10px 0 10px 30px;
  transition: background-color .2s;
  color: ${theme.palette.grey[500]};
  font-size: .875rem;
  &:hover {
    color: ${theme.palette.common.white} !important;
    text-decoration: none !important;
    background-color: ${theme.palette.primary.light};
    box-shadow: inset 3px 0 0 0 ${purple[500]};
  }
  
  &.${activeClassName} {
    background-color: ${theme.palette.primary.dark};
    box-shadow: inset 3px 0 0 0 ${theme.palette.secondary.light};
  }
}`;
