import styled from "styled-components";
import { theme } from '../theme';
export const Fc = styled.div `
  .fc-view-container {
    background-color: ${theme.palette.primary.main};
  }

  /**
   * Calendar Header
   *
   * 1. These classes aren't created by fullcalendar plugin, but it's used to 
   *    customize the header of the calender to suit Dashboard's original design.
   */

  .fc-header { /* [1] */
    height: ${theme.spacing(6)};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    line-height: ${theme.spacing(6)};
    background: ${theme.palette.secondary.main};
  }

  .fc-header-title { /* [1] */
    margin: 0 0 0 ${theme.spacing(4)};
    color: white;
    font-size: 1rem;
  }

  .fc-button-group {
    position: relative;
    top: -63px;
    right: 14px;
    height: 34px;
    border: 1px solid ${theme.palette.primary.dark};
    border-radius: 4px;
  }

  .fc-toolbar.fc-header-toolbar {
    position: relative;
    height: ${theme.spacing(6)};
    line-height: ${theme.spacing(6)};
    padding: 0 ${theme.spacing(1)};
    margin: 0;
    background-color: ${theme.palette.primary.main};

    div:first-child {
      .fc-toolbar-chunk {
        position: relative;
        width: 100%;
      }

      .fc-button-group {
        border: none;
        top: 0;
        position: absolute;
        width: 100%;
        right: inherit;
        z-index: 10;
      }
    }

    div:nth-child(2) {
      position: absolute;
      width: 100%;
      text-align: center;
    }
  }

  .fc-button,
  .fc-state-default,
  .fc-agendaDay-button,
  .fc-agendaWeek-button {
    padding: 0 ${theme.spacing(3)} !important;
    height: ${theme.spacing(4)} !important;
    background-color: ${theme.palette.secondary.main};
    box-shadow: none;
    color: ${theme.palette.grey[100]};
    font-size: ${theme.typography.fontSize}px !important;
    text-shadow: none;
    background-image: none;
    border: 0 transparent;
    outline: none!important;
    &:active {
      outline: none;
    }
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }

  .fc-month-button,
  .fc-agendaDay-button,
  .fc-agendaWeek-button {
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .fc-toolbar .fc-center {
    display: block;
    width: 100px;
    margin: 0 auto;

    h2 {
      color: ${theme.palette.common.white};
      font-size: ${theme.typography.fontSize}px;
    }

  }

  .fc-toolbar-title {
    color: white;
    font-size: ${theme.typography.fontSize}px;
  }

  /**
   * Prev / Next Arrows (Buttons)
   */

  .fc-left .fc-button-group .fc-button {
    margin: 0;
  }

  .fc-daygrid-more-link {
    cursor: pointer;
    color: black;
    font-size: 12px;
    display: block;
    margin-top: -5px;

    &:hover {
      text-decoration: none;
    }
  }

  .fc-next-button {
    position: absolute !important;
    border-radius: 100% !important;
    top: 11px;
    padding: 0 !important;
    height: 28px !important;
    width: 28px;
    background-color: ${theme.palette.primary.light};
    box-shadow: none;
    color: ${theme.palette.grey[100]};
    font-size: 8px !important;
    text-shadow: none;
    background-image: none;
    border: 0;
  }

  .fc-prev-button {
    border-radius: 100% !important;
    position: absolute !important;
    top: 11px;
    padding: 0 !important;
    height: 28px !important;
    width: 28px;
    background-color: ${theme.palette.primary.light};
    box-shadow: none;
    color: ${theme.palette.common.white};
    font-size: 8px !important;
    text-shadow: none;
    background-image: none;
    border: 0;
  }

  .fc-prev-button {
    left: 18px;
  }

  .fc-next-button {
    right: 30px;
  }


  /**
   * Days of the week - table header
   */

  .fc-col-header-cell {
    padding: 5px 0;
    border: 0;
    color: ${theme.palette.grey[700]};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    background-color: ${theme.palette.common.white};

    a {
      text-decoration: none;
      color: ${theme.palette.grey[700]};

      &:hover {
        text-decoration: none;
      }
    }
  }

  .fc-scrollgrid-sync-table {
    border-top: 1px solid ${theme.palette.common.black} !important;
  }

  /**
   * Days Number - (table cell)
   */

  .fc-day-number {
    padding: ${theme.spacing(2)} ${theme.spacing(2)} 0 0 !important;
    color: ${theme.palette.common.white};
    font-size:  ${theme.typography.fontSize}px;
    font-weight: ${theme.typography.fontWeightBold};
  }

  .fc-unthemed td {
    border-color: ${theme.palette.primary.light};
  }

  /**
   * Current Day
   */

  .fc-unthemed td.fc-today {
    background: none;
  }

  
  .fc-daygrid-day-frame {
    background-color:${theme.palette.common.white};
  }
  .fc-daygrid-day.fc-day-today {
    .fc-daygrid-day-frame {
      background-color: ${theme.palette.grey[200]};
    }
  }

  .fc-day-today {
    .fc-daygrid-day-number {
      color: ${theme.palette.error.main};
    }
  }


  /**
  * Event
  */

  .fc-event-main {
    color: ${theme.palette.primary.light};
  }

  .fc-daygrid-day-number {
    color: ${theme.palette.primary.light};
    font-weight: ${theme.typography.fontWeightBold};

    &:hover {
      color: ${theme.palette.primary.light};
      text-decoration: none;
    }
  }

  .fc-event {
    margin: 2px 0 0 0;
    padding-left: 5px;
    color: ${theme.palette.primary.light};
    font-size: 14px;
    font-weight: 600;
    background-color: rgba(39, 49, 66, .1);
    border: 0;
    border-radius: 0;
    border-left: 2px solid ${theme.palette.primary.light};

    &:hover {
      color: ${theme.palette.primary.light};
      text-decoration: none;
    }
  }

  .fc-event .fc-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px 8px 12px;
  }

  .fc-title {
    order: 1;
  }

  .fc-time {
    order: 2;
    text-transform: uppercase;
    color: ${theme.palette.primary.light};
    font-size: 12px;
    font-weight: normal !important;
  }


  /**
   * Event Color Variations
   */

  .fc-event-blue {
    //background-color: #269AF3; // fallback
    background-color: rgba(38, 154, 243, .1);
    border-color: ${theme.palette.secondary.main};
  }

  .fc-event-orange {
    //background-color: #FF7800; // fallback
    background-color: rgba(255, 120, 0, .1);
    border-color: ${theme.palette.warning.main};
  }

  .fc-event-green {
    //background-color: #1BB934; // fallback
    background-color: rgba(27, 185, 52, .1);
    border-color: ${theme.palette.success.main};
  }

`;
