import * as React from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { __ } from "../../i18n/translation";
import FormControl from '@mui/material/FormControl';
import { InputTextField, InputLabelC, InputLabelS, InputSelectField } from "../../Theme/components/InputTextField";
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import MenuItem from "@mui/material/MenuItem";
import { terms } from "../Commons/Options";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import { useTheme } from "@mui/material/styles";
const ClientForm = ({ client, mutate }) => {
    const theme = useTheme();
    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            client_id: (client === null || client === void 0 ? void 0 : client.client_id) ? client.client_id : 0,
            company_name: (client === null || client === void 0 ? void 0 : client.company_name) ? client.company_name : '',
            company_code: (client === null || client === void 0 ? void 0 : client.company_code) ? client.company_code : '',
            company_vat: (client === null || client === void 0 ? void 0 : client.company_vat) ? client.company_vat : '',
            reg_address: (client === null || client === void 0 ? void 0 : client.reg_address) ? client.reg_address : '',
            reg_city: (client === null || client === void 0 ? void 0 : client.reg_city) ? client.reg_city : '',
            reg_post: (client === null || client === void 0 ? void 0 : client.reg_post) ? client.reg_post : '',
            cor_address: (client === null || client === void 0 ? void 0 : client.cor_address) ? client.cor_address : '',
            cor_city: (client === null || client === void 0 ? void 0 : client.cor_city) ? client.cor_city : '',
            cor_post: (client === null || client === void 0 ? void 0 : client.cor_post) ? client.cor_post : '',
            terms: (client === null || client === void 0 ? void 0 : client.terms) ? client.terms : 0,
            company_email: (client === null || client === void 0 ? void 0 : client.company_email) ? client.company_email : '',
            company_phone: (client === null || client === void 0 ? void 0 : client.company_phone) ? client.company_phone : '',
            contact: (client === null || client === void 0 ? void 0 : client.contact) ? client.contact : []
        },
    });
    const { fields, append, remove } = useFieldArray({
        name: "contact",
        control
    });
    // React.useEffect(() => {
    //     for (let i = 0; i < fields.length; i++) {
    //         let name = getValues(`contact.${i}.name`);
    //         //console.log(name);
    //
    //     }
    // }, [fields, 'contact', setValue]);
    // React.useEffect(()=>{
    //     const subscription = watch((value, { name, type }) => {
    //
    //         // let str = getValues(`contact.${spl[1]}.name`);
    //         // console.log(str);
    //         const spl = name?.split('.')
    //         if(type === 'change' && spl[2] === 'name' || spl[2] === 'surname' && typeof spl[2] !== "undefined"){
    //
    //             const ind = parseInt(spl[1]);
    //             const name = (value.contact[ind].name)?parseInt(value.contact[ind].name):0;
    //             const surname = (value.contact[ind].surname)?parseInt(value.contact[ind].surname):0;
    //             setValue(`contact.${spl[1]}.email` as any, name * surname);
    //         }
    //
    //          console.log(value, name, type)
    //     });
    //     return () => subscription.unsubscribe();
    // }, [watch])
    const onSubmit = handleSubmit((values) => {
        mutate(values);
    });
    const Terms = [
        { value: 1, label: terms[1] },
        { value: 3, label: terms[2] },
        { value: 5, label: terms[3] },
        { value: 7, label: terms[4] },
        { value: 14, label: terms[5] },
        { value: 28, label: terms[6] },
        { value: 30, label: terms[7] },
        { value: 45, label: terms[8] },
        { value: 60, label: terms[9] },
        { value: 90, label: terms[10] }
    ];
    return (React.createElement("form", { onSubmit: onSubmit },
        React.createElement(Grid, { container: true, spacing: 4, sx: { pr: 4, mt: 1 } },
            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6 },
                React.createElement(Typography, { variant: "h3", sx: { fontWeight: 500, fontSize: '18px', textTransform: "uppercase" } }, __('CompanyData')),
                React.createElement(FormControl, { fullWidth: true, variant: "outlined", error: errors.company_name && errors.company_name.type === "required" },
                    React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('CompanyPerson')),
                    React.createElement(Controller, { control: control, defaultValue: '', name: "company_name", rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.company_name && errors.company_name.type === "required", 
                            // margin="dense"
                            autoComplete: "off" }, field)) }),
                    errors.company_name && errors.company_name.type === "required" &&
                        React.createElement(FormHelperText, null, __('ReqField'))),
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                    React.createElement(FormControl, { fullWidth: true, variant: "outlined", sx: { mr: 1 } },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('CompanyCode')),
                        React.createElement(Controller, { control: control, defaultValue: '', name: "company_code", render: ({ field }) => React.createElement(InputTextField
                            // margin="dense"
                            , Object.assign({ 
                                // margin="dense"
                                autoComplete: "off" }, field)) })),
                    React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('CompanyVat')),
                        React.createElement(Controller, { control: control, defaultValue: '', name: "company_vat", render: ({ field }) => React.createElement(InputTextField
                            // margin="dense"
                            , Object.assign({ 
                                // margin="dense"
                                autoComplete: "off" }, field)) }))),
                React.createElement(Typography, { variant: "h4", sx: { fontWeight: 500, fontSize: '14px', textTransform: "uppercase", pt: 2 } },
                    __('Registration'),
                    ":"),
                React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
                    React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Address')),
                    React.createElement(Controller, { control: control, defaultValue: '', name: "reg_address", render: ({ field }) => React.createElement(InputTextField
                        // margin="dense"
                        , Object.assign({ 
                            // margin="dense"
                            autoComplete: "off" }, field)) })),
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                    React.createElement(FormControl, { fullWidth: true, variant: "outlined", sx: { mr: 1 } },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('City')),
                        React.createElement(Controller, { control: control, defaultValue: '', name: "reg_city", render: ({ field }) => React.createElement(InputTextField
                            // margin="dense"
                            , Object.assign({ 
                                // margin="dense"
                                autoComplete: "off" }, field)) })),
                    React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('PostCode')),
                        React.createElement(Controller, { control: control, defaultValue: '', name: "reg_post", render: ({ field }) => React.createElement(InputTextField
                            // margin="dense"
                            , Object.assign({ 
                                // margin="dense"
                                autoComplete: "off" }, field)) })))),
            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6 },
                React.createElement(Typography, { variant: "h3", sx: { fontWeight: 500, fontSize: '18px', textTransform: "uppercase" } }, __('AdditionalData')),
                React.createElement(FormControl, { fullWidth: true, size: "small" },
                    React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, __('InvoiceTerms')),
                    React.createElement(Controller, { control: control, name: "terms", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({ onClose: (e) => {
                            } }, field), Terms.map((term) => {
                            return (React.createElement(MenuItem, { key: term.value, value: term.value }, term.label));
                        })) })),
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                    React.createElement(FormControl, { fullWidth: true, variant: "outlined", sx: { mr: 1 } },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('EmailInvoiceToSend')),
                        React.createElement(Controller, { control: control, defaultValue: '', name: "company_email", render: ({ field }) => React.createElement(InputTextField
                            // margin="dense"
                            , Object.assign({ 
                                // margin="dense"
                                autoComplete: "off" }, field)) })),
                    React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Phone')),
                        React.createElement(Controller, { control: control, defaultValue: '', name: "company_phone", render: ({ field }) => React.createElement(InputTextField
                            // margin="dense"
                            , Object.assign({ 
                                // margin="dense"
                                autoComplete: "off" }, field)) }))),
                React.createElement(Typography, { variant: "h4", sx: { fontWeight: 500, fontSize: '14px', textTransform: "uppercase", pt: 2 } },
                    __('Correspond'),
                    ":"),
                React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
                    React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Address')),
                    React.createElement(Controller, { control: control, defaultValue: '', name: "cor_address", render: ({ field }) => React.createElement(InputTextField
                        // margin="dense"
                        , Object.assign({ 
                            // margin="dense"
                            autoComplete: "off" }, field)) })),
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                    React.createElement(FormControl, { fullWidth: true, variant: "outlined", sx: { mr: 1 } },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('City')),
                        React.createElement(Controller, { control: control, defaultValue: '', name: "cor_city", render: ({ field }) => React.createElement(InputTextField
                            // margin="dense"
                            , Object.assign({ 
                                // margin="dense"
                                autoComplete: "off" }, field)) })),
                    React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('PostCode')),
                        React.createElement(Controller, { control: control, defaultValue: '', name: "cor_post", render: ({ field }) => React.createElement(InputTextField
                            // margin="dense"
                            , Object.assign({ 
                                // margin="dense"
                                autoComplete: "off" }, field)) })))),
            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12 },
                React.createElement(Typography, { variant: "h3", sx: { fontWeight: 500, fontSize: '18px', textTransform: "uppercase", mb: 2 } }, __('ResponsiblePersons')),
                React.createElement(Button, { variant: "contained", endIcon: React.createElement(PersonAddAlt1Icon, null), color: "success", sx: { color: 'white' }, onClick: () => append({
                        name: '',
                        surname: '',
                        phone: '',
                        email: '',
                        contact_id: 0
                    }) }, "Prideti asmen\u012F"),
                React.createElement(Grid, { container: true, spacing: 4, sx: { pr: 4, mt: 1 } }, fields.map((field, index) => {
                    var _a, _b;
                    return (React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 4, key: field.id },
                        React.createElement(Box, { sx: {
                                p: 2,
                                border: `solid 1px ${theme.palette.grey[300]}`,
                                borderRadius: '4px',
                                position: 'relative'
                            } },
                            React.createElement(Link, { onClick: () => remove(index), sx: { top: '10px', right: '10px', position: 'absolute' } },
                                React.createElement(DeleteIcon, null)),
                            React.createElement(Typography, { variant: "h3", sx: {
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    textTransform: "uppercase",
                                    mb: 2,
                                    display: 'flex',
                                    alignItems: "center"
                                } },
                                React.createElement(PersonIcon, null),
                                " ",
                                __('Person')),
                            React.createElement(FormControl, { fullWidth: true, variant: "outlined", error: (errors === null || errors === void 0 ? void 0 : errors.contact) && (errors === null || errors === void 0 ? void 0 : errors.contact[index]) && ((_a = errors === null || errors === void 0 ? void 0 : errors.contact[index]) === null || _a === void 0 ? void 0 : _a.name.type) === "required" },
                                React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Name')),
                                React.createElement(Controller, { control: control, defaultValue: '', rules: { required: true }, name: `contact.${index}.name`, render: ({ field }) => {
                                        var _a;
                                        return React.createElement(InputTextField
                                        // margin="dense"
                                        , Object.assign({ 
                                            // margin="dense"
                                            error: (errors === null || errors === void 0 ? void 0 : errors.contact) && (errors === null || errors === void 0 ? void 0 : errors.contact[index]) && ((_a = errors === null || errors === void 0 ? void 0 : errors.contact[index]) === null || _a === void 0 ? void 0 : _a.name.type) === "required", autoComplete: "off" }, field));
                                    } }),
                                (errors === null || errors === void 0 ? void 0 : errors.contact) && (errors === null || errors === void 0 ? void 0 : errors.contact[index]) && ((_b = errors === null || errors === void 0 ? void 0 : errors.contact[index]) === null || _b === void 0 ? void 0 : _b.name.type) === "required" &&
                                    React.createElement(FormHelperText, null, __('ReqField'))),
                            React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
                                React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('SurName')),
                                React.createElement(Controller, { control: control, defaultValue: '', name: `contact.${index}.surname`, render: ({ field }) => React.createElement(InputTextField
                                    // margin="dense"
                                    , Object.assign({ 
                                        // margin="dense"
                                        autoComplete: "off" }, field)) })),
                            React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
                                React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Phone')),
                                React.createElement(Controller, { control: control, defaultValue: '', name: `contact.${index}.phone`, render: ({ field }) => React.createElement(InputTextField
                                    // margin="dense"
                                    , Object.assign({ 
                                        // margin="dense"
                                        autoComplete: "off" }, field)) })),
                            React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
                                React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Email')),
                                React.createElement(Controller, { control: control, defaultValue: '', name: `contact.${index}.email`, render: ({ field }) => React.createElement(InputTextField
                                    // margin="dense"
                                    , Object.assign({ 
                                        // margin="dense"
                                        autoComplete: "off" }, field)) })))));
                })))),
        React.createElement(Box, { sx: { mt: 2 } },
            React.createElement(Button, { type: "submit", variant: "contained", color: "primary" },
                " ",
                __('Save')))));
};
export default ClientForm;
