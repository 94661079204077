import * as React from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from '@fullcalendar/core/locales-all';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { __ } from "../../i18n/translation";
import { Fc } from "../../Theme/components/FullCalendaCustom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { theme } from '../../Theme/theme';
const Calendar = () => {
    const renderEventContent = (eventInfo) => {
        var _a, _b, _c, _d;
        return (React.createElement(React.Fragment, null,
            React.createElement("b", null, (_b = (_a = eventInfo === null || eventInfo === void 0 ? void 0 : eventInfo.event) === null || _a === void 0 ? void 0 : _a.extendedProps) === null || _b === void 0 ? void 0 :
                _b.description, (_d = (_c = eventInfo === null || eventInfo === void 0 ? void 0 : eventInfo.event) === null || _c === void 0 ? void 0 : _c.extendedProps) === null || _d === void 0 ? void 0 :
                _d.test),
            React.createElement("i", null, eventInfo.event.title)));
    };
    const handleDateClick = (arg) => {
        console.log(arg.dateStr);
    };
    const handleEventClick = (arg) => {
        var _a, _b, _c, _d;
        arg.jsEvent.preventDefault();
        console.log((_b = (_a = arg === null || arg === void 0 ? void 0 : arg.event) === null || _a === void 0 ? void 0 : _a.extendedProps) === null || _b === void 0 ? void 0 : _b.description);
        console.log((_d = (_c = arg === null || arg === void 0 ? void 0 : arg.event) === null || _c === void 0 ? void 0 : _c.extendedProps) === null || _d === void 0 ? void 0 : _d.test);
        console.log(arg.event.url);
    };
    return (React.createElement(Fc, null,
        React.createElement(Box, { px: 2, sx: { backgroundColor: theme.palette.secondary.main, color: 'white', height: '76px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', borderTopRightRadius: '4px', borderTopLeftRadius: '4px' } },
            React.createElement(Typography, { variant: "h3", ml: 2, sx: { color: 'white' } }, __('AllEvents'))),
        React.createElement(FullCalendar, { plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin], headerToolbar: {
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,listMonth'
            }, eventContent: renderEventContent, dateClick: (e) => handleDateClick(e), eventClick: (e) => handleEventClick(e), locales: allLocales, locale: 'lt', firstDay: 1, views: {
                timeGrid: {
                    dayMaxEventRows: 1 // adjust to 6 only for timeGridWeek/timeGridDay
                }
            }, dayMaxEventRows: true, 
            // weekends={false}
            events: [
                {
                    title: 'Sara\'s birthday',
                    start: '2022-09-11',
                    className: 'fc-event-orange',
                    allDay: true,
                    description: 'Lecture',
                    url: '/note/info'
                },
                {
                    title: 'Jason',
                    start: '2022-09-11',
                    end: '2022-09-13',
                    className: 'fc-event-green',
                    extendedProps: {
                        test: 'BioChemistry'
                    },
                    allDay: true
                },
                {
                    title: 'Ruth in London',
                    start: '2022-09-11',
                    end: '2022-09-13',
                    className: 'fc-event-blue'
                },
                {
                    title: 'All Hands 4',
                    start: '2022-09-15',
                    allDay: true
                },
                {
                    title: 'Carl',
                    date: '2022-09-14',
                    className: 'fc-event-green'
                },
                {
                    title: 'Adam\'s birthday',
                    start: '2022-09-19',
                    className: 'fc-event-orange',
                    allDay: true
                },
                {
                    title: 'Business Meeting',
                    start: '2022-09-18',
                    end: '2022-09-23',
                    className: 'fc-event-green',
                    allDay: true
                },
                {
                    title: 'Vist Japan',
                    start: '2022-09-25',
                    end: '2022-09-28',
                    className: 'fc-event-blue'
                },
                {
                    title: 'Buy New Mac',
                    start: '2022-09-21',
                    className: 'fc-event-green',
                    allDay: true
                },
                {
                    title: 'New Product Launch',
                    start: '2022-09-22',
                    className: 'fc-event-green'
                }
            ] })));
};
export default Calendar;
