import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Fragment } from "react";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import BoxTitle from "../Commons/BoxTitle";
const Expenses = () => {
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { btnType: 4, title: __('Expense') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(Paper, null,
                            React.createElement(BoxTitle, { title: __('Bills') }))),
                    React.createElement(Grid, { item: true, xs: 6 }))))));
};
export default Expenses;
