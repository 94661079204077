/**
 * Created by Daisoras on 20/11/2018.
 * @link http://www.b-mod.com
 *
 */
import Polyglot from 'node-polyglot';
import Cookies from 'universal-cookie';
import { lt } from "./lt";
import { en } from './en';
const cookies = new Cookies();
var Lang;
(function (Lang) {
    Lang["en"] = "en";
    Lang["lt"] = "lt";
})(Lang || (Lang = {}));
let locale = (cookies.get('_lang')) ? cookies.get('_lang') : Lang.lt;
let phrases = lt;
if (locale === Lang.en) {
    phrases = en;
}
const polyglot = new Polyglot({ locale, phrases });
export const __ = (str, obj = {}) => {
    polyglot.extend(phrases);
    return polyglot.t(str, obj);
};
// Countable string
export function _c(str, num) {
    let NumToStr = num.toString();
    let LastOne = parseInt(num.toString().split('').pop());
    let LasTwo = parseInt(NumToStr.slice(-2));
    let trn;
    if (LasTwo)
        if (LastOne === 1) {
            if (LasTwo === 11) {
                trn = __(str + '_3', { num: num });
            }
            else {
                trn = __(str + '_1', { num: num });
            }
        }
        else if (LasTwo >= 2 && LastOne <= 9) {
            if (LasTwo >= 12 && LastOne <= 19) {
                trn = __(str + '_3', { num: num });
            }
            else {
                trn = __(str + '_2', { num: num });
            }
        }
        else {
            trn = __(str + '_3', { num: num });
        }
    return trn;
}
