import React, { Fragment, useEffect } from 'react';
import InvoicesForm from "./InvoicesForm";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router";
import Path from "../../Path";
import { useCreateInvoice } from "./useInvoices";
const CreateInvoice = () => {
    const { mutate, data, isSuccess } = useCreateInvoice();
    const history = useHistory();
    useEffect(() => {
        if (isSuccess && data) {
            history.push(`${Path.Bills}/${data}`);
        }
    }, [data]);
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { title: __('CreateClient') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Paper, { sx: { pl: 4, pb: 4 } },
                    React.createElement(InvoicesForm, { mutate: mutate }))))));
};
export default CreateInvoice;
