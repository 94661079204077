import * as CryptoJS from "crypto-js";
export const encrypt = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.SECRET_KEY).toString();
};
export const decrypt = (data) => {
    if (data) {
        let bytes = CryptoJS.AES.decrypt(data, process.env.SECRET_KEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return false;
};
export const checkTime = (interval) => {
    if (interval) {
        if (interval.search(/a day/) === 0) {
            return true;
        }
    }
    return false;
};
export const countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== "undefined"
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
};
