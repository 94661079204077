import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from '../theme';
export const DefLink = styled(Link).attrs({}) `
  text-decoration: none;
  transition: color 0.15s ease-in-out;
  color: ${theme.palette.grey[900]};
  font-size: ${theme.typography.fontSize}px;

  &:hover {
    color: ${theme.palette.secondary.main};
    text-decoration: underline;
  }
`;
export const DefLinkNoSize = styled(Link).attrs({}) `
  text-decoration: none;
  transition: color 0.15s ease-in-out;
  color: ${theme.palette.grey[900]};
  &:hover {
    color: ${theme.palette.secondary.main};
    text-decoration: none;
  }
`;
export const BadgeDiv = styled.div `
  background-color: ${theme.palette.grey[500]};
  border-radius: 4px;
  font-size: 12px;
  padding: 3px 10px;
  text-transform: uppercase;

  &.error {
    color: white;
    background-color: ${theme.palette.error.main};
  }

  &.info {
    color: white;
    background-color: ${theme.palette.info.main};
  }

  &.warning {
    color: white;
    background-color: ${theme.palette.warning.main};
  }

  &.success {
    color: white;
    background-color: ${theme.palette.success.main};
  }
`;
export const RemoveIcon = styled.div `
  cursor: pointer;
  color: ${theme.palette.grey[900]};
  &:hover {
    color: ${theme.palette.error.main};
  }
`;
export const BlueLink = styled(Link).attrs({}) `
  text-decoration: none;
  transition: color 0.15s ease-in-out;
  color: ${theme.palette.secondary.main};
  font-size: ${theme.typography.fontSize}px;

  &:hover {
    color: ${theme.palette.secondary.main};
    text-decoration: underline;
  }
`;
