import * as React from "react";
import { Fragment } from "react";
import Path from "../../Path";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { theme } from '../../Theme/theme';
import { DefLink, RemoveIcon } from '../../Theme/components/Commons';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
const InvoiceLine = props => {
    const { invoice: { company_name, invoice_series, invoice_number, invoice_id }, RemoveInvoice } = props;
    return (React.createElement(Fragment, null,
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, px: 4, py: 1, borderBottom: `solid 1px ${theme.palette.grey[300]}` } },
                React.createElement(Grid, { container: true, spacing: 2, sx: { alignItems: "center" } },
                    React.createElement(Grid, { item: true, xs: 8 },
                        React.createElement(Box, { sx: {} },
                            React.createElement(DefLink, { to: `${Path.Bills}/${invoice_id}` }, company_name))),
                    React.createElement(Grid, { item: true, xs: 3 },
                        React.createElement(Box, { sx: { color: theme.palette.grey[700], fontSize: theme.typography.fontSize } }, company_name)),
                    React.createElement(Grid, { item: true, xs: 1 },
                        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                            React.createElement(DefLink, { to: `${Path.Bills}/${invoice_id}` },
                                React.createElement(VisibilityIcon, null)),
                            React.createElement(RemoveIcon, { onClick: () => RemoveInvoice({ invoice_series, invoice_number, invoice_id }) },
                                React.createElement(DeleteIcon, null)))))))));
};
export default InvoiceLine;
