import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Fragment, useEffect, useState } from "react";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import BoxTitle from "../Commons/BoxTitle";
import { removeInvoice, useInvoices } from "./useInvoices";
import { theme } from "../../Theme/theme";
import InvoiceLine from "./InvoiceLine";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
const Invoices = () => {
    var _a;
    const { isLoading, isError, data: Invoices, error, refetch, status } = useInvoices({});
    const [modal, setModal] = useState({ open: false, invoice: { invoice_id: 0, invoice_series: '', invoice_number: '' } });
    const remove = removeInvoice();
    const Cancel = () => {
        setModal({ open: false, invoice: { invoice_id: 0, invoice_series: '', invoice_number: '' } });
    };
    const Continue = () => {
        remove.mutate({ invoiceId: modal.invoice.invoice_id });
        setModal({ open: false, invoice: { invoice_id: 0, invoice_series: '', invoice_number: '' } });
    };
    const RemoveInvoice = (invoice) => {
        setModal({
            open: true,
            invoice: {
                invoice_id: invoice.invoice_id,
                invoice_series: invoice.invoice_series,
                invoice_number: invoice.invoice_number
            }
        });
    };
    useEffect(() => {
        if (remove.isSuccess) {
            refetch();
        }
    }, [remove.isSuccess]);
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Dialog, { open: modal.open, onClose: Cancel, "aria-labelledby": "session-dialog-title", "aria-describedby": "session-dialog-description" },
            React.createElement(DialogTitle, { id: "session-dialog-title" }, "Ar tikrai norit pa\u0161alinti saskit\u0105!"),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, { id: "session-dialog-description" }, (_a = modal.invoice) === null || _a === void 0 ? void 0 : _a.invoice_id)),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: Cancel, variant: "contained", color: "primary" },
                    " ",
                    __('Cancel')),
                React.createElement(Button, { onClick: Continue, variant: "contained", color: "error" },
                    " ",
                    __('Yes')))),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { btnType: 3, title: __('Invoices') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Paper, null,
                            React.createElement(BoxTitle, { title: __('Bills'), count: Invoices === null || Invoices === void 0 ? void 0 : Invoices.length }),
                            React.createElement(Box, { sx: { display: 'flex', backgroundColor: `${theme.palette.grey[200]}` } },
                                React.createElement(Box, { component: "main", sx: {
                                        flexGrow: 1,
                                        px: 4,
                                        py: 1,
                                        borderBottom: `solid 1px ${theme.palette.grey[300]}`
                                    } },
                                    React.createElement(Grid, { container: true, spacing: 2, sx: { alignItems: "center" } },
                                        React.createElement(Grid, { item: true, xs: 8 },
                                            React.createElement(Box, { sx: {} }, "Klientas")),
                                        React.createElement(Grid, { item: true, xs: 3 }, "\u012Emones kodas"),
                                        React.createElement(Grid, { item: true, xs: 1 }, "Veiksmai")))), Invoices === null || Invoices === void 0 ? void 0 :
                            Invoices.map((invoice, index) => {
                                return (React.createElement(InvoiceLine, { key: index, invoice: invoice, RemoveInvoice: RemoveInvoice }));
                            }))))))));
};
export default Invoices;
