import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Fragment, useEffect, useState } from "react";
import Path from "../../Path";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import { removeMember, useMembers } from "./useUsers";
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { DefLinkNoSize } from "../../Theme/components/Commons";
import { positions } from "../Commons/Options";
import { AppContext } from "../../AppContext";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
const Users = () => {
    var _a, _b;
    const { isLoading, isError, data, error, refetch } = useMembers();
    const remove = removeMember();
    const [modal, setModal] = useState({ open: false, user: { user_id: 0, name: '', surename: '' } });
    const { role } = React.useContext(AppContext);
    const Cancel = () => {
        setModal({ open: false, user: { user_id: 0, name: '', surename: '' } });
    };
    const Continue = () => {
        remove.mutate({ userId: modal.user.user_id });
        setModal({ open: false, user: { user_id: 0, name: '', surename: '' } });
    };
    const RemoveContact = (user) => {
        setModal({
            open: true,
            user: {
                user_id: user.user_id,
                name: user.name,
                surename: user.surename
            }
        });
    };
    useEffect(() => {
        if (remove.isSuccess) {
            refetch();
        }
    }, [remove.isSuccess]);
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Dialog, { open: modal.open, onClose: Cancel, "aria-labelledby": "session-dialog-title", "aria-describedby": "session-dialog-description" },
            React.createElement(DialogTitle, { id: "session-dialog-title" }, "Ar tikrai norit pa\u0161alinti nar\u012F!"),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, { id: "session-dialog-description" }, (_a = modal.user) === null || _a === void 0 ? void 0 :
                    _a.name,
                    " ", (_b = modal.user) === null || _b === void 0 ? void 0 :
                    _b.surename)),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: Cancel, variant: "contained", color: "primary" },
                    " ",
                    __('Cancel')),
                React.createElement(Button, { onClick: Continue, variant: "contained", color: "error" },
                    " ",
                    __('Yes')))),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { btnType: 1, title: __('Members') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Grid, { container: true, spacing: 4 }, data === null || data === void 0 ? void 0 : data.map((user) => {
                    return (React.createElement(Grid, { key: user.user_id, item: true, xs: 12, sm: 12, md: 6, lg: 4, xl: 3 },
                        React.createElement(Paper, { sx: { p: 3, position: 'relative' } },
                            React.createElement(Box, { sx: {
                                    bottom: '10px',
                                    right: '10px',
                                    position: 'absolute',
                                    cursor: 'pointer'
                                } },
                                React.createElement("div", { onClick: () => RemoveContact(user) },
                                    React.createElement(DeleteIcon, null))),
                            React.createElement(Grid, { container: true },
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(Avatar, { alt: `${user.name} ${user.surename}`, src: (user === null || user === void 0 ? void 0 : user.avatar) && `${process.env.PROXY_API_URL}/uploads/profile/${user.user_id}/${user === null || user === void 0 ? void 0 : user.avatar}`, sx: { width: 80, height: 80 } })),
                                React.createElement(Grid, { item: true, xs: 8 },
                                    React.createElement(Typography, { variant: "body1", sx: { fontWeight: 600, fontSize: '18px' } }, (role === 1) ?
                                        React.createElement(DefLinkNoSize, { to: `${Path.Users}/${user.user_id}` },
                                            user.name,
                                            " ",
                                            user.surename) :
                                        React.createElement(Fragment, null,
                                            user.name,
                                            " ",
                                            user.surename)),
                                    React.createElement(Box, null,
                                        React.createElement(Box, { sx: {
                                                mb: 2,
                                                fontSize: '14px'
                                            } },
                                            " ",
                                            positions[user === null || user === void 0 ? void 0 : user.position]),
                                        (user === null || user === void 0 ? void 0 : user.phone) && React.createElement(Box, { sx: {
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            } },
                                            React.createElement(PhoneAndroidIcon, { sx: { fontSize: '14px', mr: 1 } }), user === null || user === void 0 ? void 0 :
                                            user.phone,
                                            React.createElement("br", null)),
                                        React.createElement(Box, { sx: {
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            } },
                                            React.createElement(EmailIcon, { sx: { fontSize: '14px', mr: 1 } }),
                                            user.email)))))));
                }))))));
};
export default Users;
