var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const prepareBody = (body) => {
    if (!body) {
        return undefined;
    }
    return body instanceof FormData ? body : JSON.stringify(body);
};
const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    else {
        const error = new Error(response.statusText);
        error.status = response.status;
        throw error;
    }
};
const get = (path, headers = { 'Content-Type': 'application/json' }, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const request = Object.assign({ method: 'GET', headers }, requestInit);
    return yield fetch(path, request).then(checkStatus).then(res => res.json());
});
const post = (path, body, headers = { 'Content-Type': 'application/json' }, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const request = Object.assign(Object.assign({ method: 'POST', headers }, requestInit), { body: prepareBody(body) });
    return yield fetch(path, request).then(checkStatus).then(res => res.json());
});
const put = (path, body, headers = { 'Content-Type': 'application/json' }, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const request = Object.assign(Object.assign({ method: 'PUT', headers }, requestInit), { body: prepareBody(body) });
    return yield fetch(path, request).then(checkStatus).then(res => res.json());
});
const del = (path, headers = { 'Content-Type': 'application/json' }, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const request = Object.assign({ method: 'DELETE', headers }, requestInit);
    return yield fetch(path, request).then(checkStatus).then(res => res.json());
});
const useFetch = { get, post, put, del };
export { useFetch };
