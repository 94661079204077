import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { __ } from "../../i18n/translation";
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import { InputTextField, InputLabelC, InputSelectSearch } from "../../Theme/components/InputTextField";
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { useSellerCreate } from "./useExpenses";
import { useEffect } from "react";
import { Autocomplete } from "@mui/material";
import { countries } from "../Commons/Options";
import { countryToFlag } from "../tools";
const SellerForm = ({ cancel, setValue, setSellerName }) => {
    const { handleSubmit, control, formState: { errors }, setValue: localSetValue } = useForm({ defaultValues: {
            seller_country: 'LT',
            seller_name: '',
            seller_code: '',
            seller_vat: ''
        } });
    const { mutate, data, isSuccess, isError } = useSellerCreate();
    const onSubmit = handleSubmit((values) => {
        mutate(values);
    });
    useEffect(() => {
        if (isSuccess) {
            setValue('seller_name', data.seller_name);
            setValue('seller_code', data.seller_code);
            setValue('seller_vat', data.seller_vat);
            setValue('seller_country', data.seller_country);
            setValue('seller_id', data.sellerId);
            setSellerName({ seller_name: data.seller_name });
            cancel();
        }
    }, [isSuccess, data]);
    return (React.createElement("form", { onSubmit: onSubmit },
        isError && React.createElement(Alert, { severity: "error" }, " Toks pardav\u0117jas jau egzistuoja "),
        React.createElement(FormControl, { fullWidth: true, error: errors.seller_name && errors.seller_name.type === "required" },
            React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, "Imon\u0117s pavadinimas"),
            React.createElement(Controller, { control: control, name: "seller_name", defaultValue: '', rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.seller_name && errors.seller_name.type === "required" }, field)) }),
            errors.seller_name && errors.seller_name.type === "required" &&
                React.createElement(FormHelperText, null, __('ReqField'))),
        React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
            React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, "Imon\u0117s kodas"),
            React.createElement(Controller, { control: control, defaultValue: '', name: "seller_code", render: ({ field }) => React.createElement(InputTextField
                // margin="dense"
                , Object.assign({ 
                    // margin="dense"
                    autoComplete: "off" }, field)) })),
        React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
            React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, "Imon\u0117s PVM kodas"),
            React.createElement(Controller, { control: control, defaultValue: '', name: "seller_vat", render: ({ field }) => React.createElement(InputTextField
                // margin="dense"
                , Object.assign({ 
                    // margin="dense"
                    autoComplete: "off" }, field)) })),
        React.createElement(FormControl, { sx: { mt: '30px' }, size: "small", fullWidth: true, error: errors.seller_name && errors.seller_name.type === "required" },
            React.createElement(InputLabelC, { sx: { top: '-30px' }, shrink: true, htmlFor: "bootstrap-input" }, "\u0160alis "),
            React.createElement(Controller, { control: control, name: "seller_country", rules: { required: true }, render: () => (React.createElement(Autocomplete, { disablePortal: true, options: countries, defaultValue: { code: "LT", label: "Lithuania", phone: "370" }, getOptionLabel: (option) => option.label || '', isOptionEqualToValue: (option, value) => option.code === value.code, renderOption: (props, option, state) => (React.createElement("li", Object.assign({}, props),
                        countryToFlag(option.code),
                        " ",
                        option.label)), fullWidth: true, onChange: (event, value) => {
                        localSetValue('seller_country', value.code);
                    }, renderInput: (params) => React.createElement(InputSelectSearch, Object.assign({ error: errors.seller_country && errors.seller_country.type === "required" }, params)) })) })),
        React.createElement(Box, { sx: { mt: 2 } },
            React.createElement(Button, { onClick: () => cancel(), variant: "contained", color: "primary" },
                " ",
                __('Cancel')),
            React.createElement(Button, { type: "submit", variant: "contained", color: "success", sx: { color: 'white', ml: 1 } },
                " ",
                __('Save')))));
};
export default SellerForm;
