import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Fragment, useEffect } from "react";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import ClientForm from "./ClientForm";
import { useCreateClient } from "./useClients";
import Path from "../../Path";
import { useHistory } from "react-router";
const CreateClient = () => {
    const { mutate, data, isSuccess } = useCreateClient();
    const history = useHistory();
    const client = { company_name: "", terms: 1, client_id: 0 };
    useEffect(() => {
        if (isSuccess && data) {
            history.push(`${Path.Clients}/${data}`);
        }
    }, [data]);
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { title: __('CreateClient') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Paper, { sx: { pl: 4, pb: 4 } },
                    React.createElement(ClientForm, { client: client, mutate: mutate }))))));
};
export default CreateClient;
