import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return React.createElement(MuiAlert, Object.assign({ elevation: 6, ref: ref, variant: "filled" }, props));
});
export default function SnackBar({ type, msg }) {
    const [open, setOpen] = React.useState(true);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (React.createElement(Stack, { spacing: 2, sx: { width: '100%' } },
        type === 1 && React.createElement(Snackbar, { open: open, autoHideDuration: 6000, onClose: handleClose, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
            React.createElement(Alert, { onClose: handleClose, severity: "success", sx: { width: '100%', color: 'white' } },
                " ",
                msg,
                " ")),
        type === 2 && React.createElement(Snackbar, { open: open, autoHideDuration: 6000, onClose: handleClose, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
            React.createElement(Alert, { onClose: handleClose, severity: "error", sx: { width: '100%', color: 'white' } },
                " ",
                msg,
                " ")),
        type === 3 && React.createElement(Snackbar, { open: open, autoHideDuration: 6000, onClose: handleClose, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
            React.createElement(Alert, { onClose: handleClose, severity: "warning", sx: { width: '100%', color: 'white' } },
                " ",
                msg,
                " ")),
        type === 4 && React.createElement(Snackbar, { open: open, autoHideDuration: 6000, onClose: handleClose, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
            React.createElement(Alert, { onClose: handleClose, severity: "info", sx: { width: '100%', color: 'white' } },
                " ",
                msg,
                " "))));
}
