import * as React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
const ProfileSkeleton = () => {
    const theme = useTheme();
    return (React.createElement(Grid, { container: true, spacing: 4, sx: { mt: 2, pr: 4 } },
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 2 },
            React.createElement(Skeleton, { variant: "circular", animation: "wave", sx: { bgcolor: theme.palette.grey[100] } },
                React.createElement(Avatar, { sx: { width: 150, height: 150 } }))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 5 },
            React.createElement(Box, null,
                React.createElement(Skeleton, { animation: "wave", width: "100%", height: 60, sx: { bgcolor: theme.palette.grey[100] } }),
                React.createElement(Skeleton, { animation: "wave", width: "100%", height: 60, sx: { bgcolor: theme.palette.grey[100] } }),
                React.createElement(Skeleton, { animation: "wave", width: "100%", height: 60, sx: { bgcolor: theme.palette.grey[100] } }))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 5 },
            React.createElement(Box, null,
                React.createElement(Skeleton, { animation: "wave", width: "100%", height: 60, sx: { bgcolor: theme.palette.grey[100] } }),
                React.createElement(Skeleton, { animation: "wave", width: "100%", height: 60, sx: { bgcolor: theme.palette.grey[100] } }),
                React.createElement(Skeleton, { animation: "wave", width: "100%", height: 60, sx: { bgcolor: theme.palette.grey[100] } })))));
};
export default ProfileSkeleton;
