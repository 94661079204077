import { createTheme } from "@mui/material/styles";
export const rem = (pix) => {
    return `${(pix / 16)}rem`;
};
export const theme = createTheme({
    typography: {
        fontFamily: [
            "Source Sans Pro",
            "sans-serif"
        ].join(","),
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        h1: {
            fontSize: rem(36),
            // lineHeight: '',
            fontWeight: 500
        },
        h2: {
            fontSize: rem(28),
            // lineHeight: '',
            fontWeight: 500
        },
        h3: {
            fontSize: rem(24),
            // lineHeight: '',
            fontWeight: 500
        },
        h4: {
            fontSize: rem(20),
            // lineHeight: '',
            fontWeight: 500
        },
        h5: {
            fontSize: rem(18),
            // lineHeight: '',
            fontWeight: 500
        },
        h6: {
            fontSize: rem(16),
            // lineHeight: '',
            fontWeight: 500
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#273142',
            light: '#475364',
            dark: '#1d2531'
        },
        secondary: {
            main: '#1990ea',
            light: '#53b2f9',
            dark: '#1a91eb'
        },
        error: {
            main: '#ed1c24',
            light: '#f95359',
            dark: '#db161e'
        },
        warning: {
            main: '#ff7800',
            light: '#f7bf47',
            dark: '#f16911'
        },
        info: {
            main: '#2ea1f8',
            light: '#86CAFF',
            dark: '#1a91eb'
        },
        success: {
            main: '#39b54a',
            light: '#65dc52',
            dark: '#249533'
        },
        grey: {
            50: '#f5f8fa',
            100: '#f2f4f7',
            200: '#e6eaee',
            300: '#dfe3e9',
            400: '#bbc5d5',
            500: '#b7c0cd',
            600: '#9fa9ba',
            700: '#7f8fa4',
            800: '#717f92',
            900: '#5a6574',
            A100: '#f2f4f7',
            A200: '#e6eaee',
            A400: '#bbc5d5',
            A700: '#7f8fa4',
        },
        text: {
            primary: '#273142',
        },
        background: {
            default: "#eff3f6",
        },
    },
    // @ts-ignore
    shadows: [
        "none",
        "0px 2px 1px -1px rgba(183, 192, 205,0.2),0px 1px 1px 0px rgba(183, 192, 205,0.14),0px 1px 3px 0px rgba(183, 192, 205,0.12)",
        "0px 3px 1px -2px rgba(183, 192, 205,0.2),0px 2px 2px 0px rgba(183, 192, 205,0.14),0px 1px 5px 0px rgba(183, 192, 205,0.12)",
        "0px 3px 3px -2px rgba(183, 192, 205,0.2),0px 3px 4px 0px rgba(183, 192, 205,0.14),0px 1px 8px 0px rgba(183, 192, 205,0.12)",
        "0px 2px 4px -1px rgba(183, 192, 205,0.2),0px 4px 5px 0px rgba(183, 192, 205,0.14),0px 1px 10px 0px rgba(183, 192, 205,0.12)",
        ...Array(20).fill('none')
    ]
});
