import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
export const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-container": {
        "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "500px", // Set your width here
        }
    },
}));
