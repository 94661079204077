const g_path = '/';
const api_path = '/api/';
const Path = {
    Home: g_path,
    Login: g_path + 'login',
    Auth: g_path + 'auth',
    ForgotPass: g_path + 'forgot-pass',
    Clients: g_path + 'clients',
    Users: g_path + 'users',
    Profile: g_path + 'profile',
    Analytics: g_path + 'analytics',
    Bills: g_path + 'bills',
    Expenses: g_path + 'expenses',
    Projects: g_path + 'projects',
    ProfileUpdate: g_path + 'profile-update',
    //API
    ApiLogin: api_path + 'login',
    ApiResetLogin: api_path + 'reset-cookie',
    ApiTasks: api_path + 'tasks',
    ApiClients: api_path + 'clients',
    ApiClient: api_path + 'client',
    ApiMembers: api_path + 'members',
    ApiProfileUp: api_path + 'profile-up',
    ApiProfileAdd: api_path + 'profile-add',
    ApiProfile: api_path + 'profile',
    ApiPass: api_path + 'pass',
    ApiGetFile: api_path + 'file',
    ApiProjects: api_path + 'projects',
    ApiInvoices: api_path + 'invoices',
    ApiSeller: api_path + 'seller',
    ApiSellers: api_path + 'sellers',
};
export default Path;
