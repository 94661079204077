export const en = {
    month: {
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
    },
    month1: {
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
    },
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
    logH: 'Welcome to Workspace',
    logP: 'An Ideas and a Technology to improve your business.',
    Password: 'Password',
    Email: "Email",
    LoginEmail: 'Log in with your e-mail address',
    Phone: 'Phone',
    Mob: 'Mobile',
    login: "Login",
    forgotYourPassword: "Forgot your password?",
    InvalidLogin: "Invalid Login",
    emailExist: "This Email is registered",
    // validations
    ReqField: 'Required field',
    MinField: 'Cannot be 0',
    MinAmount: 'Cannot be 0',
    BadFormat: 'Bad Format',
    MoreNum: 'Please enter at least %{num} characters',
    Overview: "Overview",
    Statistics: "Statistics",
    Analytics: "Analytics",
    Clients: 'Clients',
    Bills: "Bills",
    Members: 'Members',
    Member: 'Member',
    Projects: "Projects",
    Continue: "Continue",
    Continue1: "Your session will end after 00.",
    Continue2: " min. Want to continue working?",
    AllEvents: "All Events",
    Tasks: 'Tasks',
    Users: "Users",
    DragFile: 'Drag a file here or browse for a file to upload.',
    ErrorFileFormat: 'File upload error: Only %{formats} files can be attached, maximum size allowed is %{size}MB.',
    Remove: 'Remove',
    Name: "Name",
    SurName: "Surname",
    Profile: "Profile",
    Photo: "Photo",
    Administrator: 'Administrator',
    Manager: 'Manager',
    User: 'User',
    Client: 'Client',
    Position: 'Position',
    Designer: 'Designer',
    Programmer: 'Programmer',
    ProjectManager: 'Project Manager',
    BusinessDevelopmentManager: 'Business Development Manager',
    Accountant: 'Accountant',
    TheDirector: 'The Director',
    TheDirectorLabel: 'The Director',
    Save: "Save",
    UpdateSuccess: "Saved successfully",
    Yes: "Yes",
    No: "No",
    Cancel: 'Cancel',
    Back: 'Back',
    Close: 'Close',
    AboutClient: 'About Client',
    CreateClient: 'Create Client',
    AdditionalData: 'Additional Data',
    CompanyData: 'Company\'s data',
    CompanyPerson: 'Company  / Person',
    CompanyCode: 'Company Code',
    CompanyVat: 'Company VAT',
    BankName: 'Bank Name',
    BankAccount: 'Bank Account',
    Registration: 'Registration',
    Address: 'Addresss',
    TreatySignatory: 'Treaty signatory\'s',
    SignatureName: 'Signature Name',
    SignatureLastName: 'Signature Last Name',
    InvoiceTerms: 'Invoice Terms',
    EmailInvoiceToSend: 'E-mail invoice to send',
    CorrespondenceAddress: 'Correspondence Address',
    ResponsiblePersons: 'Responsible Persons',
    City: "City",
    PostCode: "Post Code",
    Correspond: 'Correspondent',
    DueOnReceipt: 'Due On Receipt',
    ThreeDays: '3 Days',
    FiveDays: '5 days',
    SevenDays: '7 days',
    FourteenDays: '14 days',
    RwentyEightDays: '28 days',
    ThirtyDays: '30 days',
    FortyFiveDays: '45 days',
    SixtyDays: '60 days',
    NinetiesDays: '90 days',
    Person: 'Person',
    Expense: 'Expense',
    Invoices: 'Invoices',
    AccountNumber: 'Account number',
    CompanyName: 'Company name',
    AccountType: 'Account type',
    invoiceIsIssued: 'invoice is issued',
    DateFrom: 'Start date',
    DateTo: 'Final date'
};
