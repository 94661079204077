import { useFetch } from "../ApiService";
import Path from "../../Path";
import { useMutation, useQuery } from "react-query";
export const useInvoices = ({ from = '', to = '', limit = '' }) => {
    const getInvoices = () => {
        return useFetch.get(`${Path.ApiInvoices}/?to=${to}&limit=${limit}&from=${from}`);
    };
    return useQuery(['invoices'], getInvoices);
};
export const useCreateInvoice = () => {
    const fetchInvoice = (values) => {
        return useFetch.post(`${Path.ApiInvoices}`, { invoice: values });
    };
    return useMutation((values) => fetchInvoice(values), {});
};
export const useInvoice = ({ invoiceId }) => {
    const fetchInvoiceById = () => {
        return useFetch.get(`${Path.ApiInvoices}/${invoiceId}`);
    };
    return useQuery(['invoice', invoiceId], fetchInvoiceById);
};
export const useInvoiceUpdate = () => {
    const fetchInvoice = (values) => {
        return useFetch.put(`${Path.ApiInvoices}`, { invoice: values });
    };
    return useMutation((values) => fetchInvoice(values), {});
};
export const removeInvoice = () => {
    const fetchInvoice = ({ invoiceId }) => {
        return useFetch.del(`${Path.ApiInvoices}/${invoiceId}`);
    };
    return useMutation((invoiceId) => fetchInvoice(invoiceId), {});
};
