import React from 'react';
import MilestoneForm from "./MilestoneForm";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { StyledDialog } from "../../Theme/components/Dialog";
const StageDialog = ({ onClose, open }) => {
    return (React.createElement(StyledDialog, { onClose: onClose, open: open },
        React.createElement(Box, { sx: { p: 3 } },
            React.createElement(Typography, { variant: "body1", sx: { fontWeight: 600, fontSize: '18px' } }, "Prid\u0117ti etap\u0105"),
            React.createElement(MilestoneForm, { handleClose: onClose }))));
};
export default StageDialog;
