import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { __ } from "../../i18n/translation";
import Grid from '@mui/material/Grid';
import { useForm, Controller } from "react-hook-form";
import FileDropzone from "../Commons/FileDropzone";
import FormControl from "@mui/material/FormControl";
import { InputLabelC, InputLabelS, InputTextField, InputSelectField } from "../../Theme/components/InputTextField";
import FormHelperText from "@mui/material/FormHelperText";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { ZoneSingle } from "../../Theme/components/Dropzone";
import { positions, roles } from "../Commons/Options";
const ProfileForm = ({ user, type, role, mutate }) => {
    const [removed, setRemoved] = useState(false);
    const { register, unregister, handleSubmit, formState: { errors }, control, setValue, getValues, watch } = useForm({
        defaultValues: {
            user_id: (user === null || user === void 0 ? void 0 : user.user_id) ? user.user_id : 0,
            email: (user === null || user === void 0 ? void 0 : user.email) ? user.email : '',
            phone: (user === null || user === void 0 ? void 0 : user.phone) ? user.phone : '',
            position: (user === null || user === void 0 ? void 0 : user.position) ? user.position : 2,
            role: (user === null || user === void 0 ? void 0 : user.role) ? user.role : 4,
            name: (user.name) ? user.name : '',
            surename: (user === null || user === void 0 ? void 0 : user.surename) ? user.surename : '',
            files: (user === null || user === void 0 ? void 0 : user.files) ? user.files : [],
            password: '',
            avatar: (user === null || user === void 0 ? void 0 : user.avatar) ? user.avatar : '',
        },
    });
    const Roles = [
        { label: roles[1], value: 1 },
        { label: roles[2], value: 2 },
        { label: roles[3], value: 3 },
        { label: roles[4], value: 4 },
        { label: roles[5], value: 5 }
    ];
    const Positions = [
        { value: 1, label: positions[1] },
        { value: 2, label: positions[2] },
        { value: 3, label: positions[3] },
        { value: 4, label: positions[4] },
        { value: 5, label: positions[5] },
        { value: 6, label: positions[6] }
    ];
    const files = watch('files');
    useEffect(() => {
        register('files');
        if (removed) {
            setValue('avatar', '');
        }
        return () => {
            unregister('files');
        };
    }, [register, unregister, removed]);
    const onSubmit = handleSubmit((values) => {
        mutate(values);
    });
    return (React.createElement(Fragment, null,
        React.createElement("form", { onSubmit: onSubmit },
            React.createElement(Grid, { container: true, spacing: 4, sx: { pr: 4, mt: 1 } },
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 2 },
                    React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Photo')),
                    ((user === null || user === void 0 ? void 0 : user.avatar) && !removed) ?
                        React.createElement(ZoneSingle, null,
                            React.createElement(Avatar, { alt: "", src: `${process.env.PROXY_API_URL}/uploads/profile/${user.user_id}/${user === null || user === void 0 ? void 0 : user.avatar}`, sx: { width: 150, height: 150, mb: '20px' } }),
                            React.createElement(Button, { variant: "contained", onClick: () => setRemoved(true), size: "small" }, __('Remove'))) :
                        React.createElement(FileDropzone, { getValues: getValues, setValue: setValue, files: files, multiple: false, size: 10, profile: true, formats: 'jpeg, jpg, .png', accept: {
                                'image/jpeg': ['.jpeg', '.jpg'],
                                'image/png': ['.png'],
                            } })),
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 5 },
                    React.createElement(FormControl, { fullWidth: true, error: errors.name && errors.name.type === "required" },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Name')),
                        React.createElement(Controller, { control: control, name: "name", defaultValue: '', rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.name && errors.name.type === "required" }, field)) }),
                        errors.name && errors.name.type === "required" &&
                            React.createElement(FormHelperText, null, __('ReqField'))),
                    React.createElement(FormControl, { fullWidth: true, error: errors.email && errors.email.type === "required" || errors.email && errors.email.type === "pattern" },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Email')),
                        React.createElement(Controller, { control: control, name: "email", defaultValue: '', rules: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.email && errors.email.type === "required" || errors.email && errors.email.type === "pattern" }, field)) }),
                        errors.email && errors.email.type === "required" &&
                            React.createElement(FormHelperText, null, __('ReqField')),
                        errors.email && errors.email.type === "pattern" &&
                            React.createElement(FormHelperText, null, __('BadFormat'))),
                    React.createElement(FormControl, { fullWidth: true, size: "small" },
                        React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, __('Position')),
                        React.createElement(Controller, { control: control, name: "position", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({ onClose: (e) => {
                                    console.log('e.target');
                                } }, field), Positions.map((position) => {
                                return (React.createElement(MenuItem, { key: position.value, value: position.value }, position.label));
                            })) })),
                    role === 1 && type == 1 && React.createElement(FormControl, { fullWidth: true, variant: "outlined", error: errors.password && errors.password.type === "required" || errors.password && errors.password.type === "minLength" },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Password')),
                        React.createElement(Controller, { control: control, defaultValue: '', name: "password", rules: { required: (user.user_id === 0), minLength: 8 }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.password && errors.password.type === "required" || errors.password && errors.password.type === "minLength", 
                                // margin="dense"
                                autoComplete: "off", type: "password" }, field)) }),
                        errors.password && errors.password.type === "required" &&
                            React.createElement(FormHelperText, null, __('ReqField')),
                        errors.password && errors.password.type === "minLength" &&
                            React.createElement(FormHelperText, null, __('MoreNum', { num: 8 })))),
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 5 },
                    React.createElement(FormControl, { fullWidth: true, error: errors.surename && errors.surename.type === "required" },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('SurName')),
                        React.createElement(Controller, { control: control, name: "surename", defaultValue: '', rules: { required: true }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.surename && errors.surename.type === "required" }, field)) }),
                        errors.surename && errors.surename.type === "required" &&
                            React.createElement(FormHelperText, null, __('ReqField'))),
                    React.createElement(FormControl, { fullWidth: true, error: errors.phone && errors.phone.type === "required" || errors.phone && errors.phone.type === "minLength" || errors.phone && errors.phone.type === "maxLength" || errors.phone && errors.phone.type === "pattern" },
                        React.createElement(InputLabelC, { shrink: true, htmlFor: "bootstrap-input" }, __('Phone')),
                        React.createElement(Controller, { control: control, defaultValue: '', name: "phone", rules: {
                                // required: true,
                                maxLength: 12,
                                minLength: 12,
                                pattern: /^\+\d+$/
                            }, render: ({ field }) => React.createElement(InputTextField, Object.assign({ error: errors.phone && errors.phone.type === "required" || errors.phone && errors.phone.type === "minLength" || errors.phone && errors.phone.type === "maxLength" || errors.phone && errors.phone.type === "pattern" }, field)) }),
                        errors.phone && errors.phone.type === "required" &&
                            React.createElement(FormHelperText, null, __('ReqField')),
                        errors.phone && errors.phone.type === "minLesngth" &&
                            React.createElement(FormHelperText, null, "Netinkamas formatas"),
                        errors.phone && errors.phone.type === "minLength" &&
                            React.createElement(FormHelperText, null, "Netinkamas formatas"),
                        errors.phone && errors.phone.type === "patter" &&
                            React.createElement(FormHelperText, null, "Neteisingas telefono formatas turi b\u016Bti +370...")),
                    role === 1 && React.createElement(FormControl, { fullWidth: true, size: "small" },
                        React.createElement(InputLabelS, { shrink: true, htmlFor: "bootstrap-input" }, __('Role')),
                        React.createElement(Controller, { control: control, name: "role", rules: { required: true }, render: ({ field }) => React.createElement(InputSelectField, Object.assign({ onClose: (e) => {
                                    console.log('e.target');
                                } }, field), Roles.map((role) => {
                                return (React.createElement(MenuItem, { key: role.value, value: role.value }, role.label));
                            })) })),
                    React.createElement(Box, { sx: { pt: 4, white: '100%', display: 'flex', justifyContent: 'flex-end' } },
                        React.createElement(Button, { variant: "contained", type: "submit" }, __('Save'))))))));
};
export default ProfileForm;
