import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Fragment, useEffect, useState } from "react";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import { useClient } from "./useClients";
import ProfileSkeleton from "../Commons/ProfileSkeleton";
import { useParams } from "react-router-dom";
import ClientForm from "./ClientForm";
import { useClientUpdate } from "./useClients";
import SnackBar from "../Commons/Snackbar";
const Client = () => {
    const { id } = useParams();
    const clientId = parseInt(id);
    const { isLoading, isError, data: useClientData, isSuccess: useClientIsSuccess, refetch } = useClient({ clientId: clientId });
    const [clientData, setClient] = useState(useClientData);
    const { mutate, data: useUpdateData, isSuccess: updateIsSuccess } = useClientUpdate();
    useEffect(() => {
        if (updateIsSuccess && useUpdateData) {
            setClient(useUpdateData);
            refetch();
        }
        if (useClientIsSuccess && useClientData) {
            setClient(useClientData);
        }
    }, [useUpdateData, useClientData, useClientIsSuccess, updateIsSuccess]);
    console.log(useUpdateData);
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        useUpdateData && React.createElement(SnackBar, { type: 1, msg: __('UpdateSuccess') }),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { title: __('AboutClient') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Paper, { sx: { pl: 4, pb: 4 } },
                    isLoading && React.createElement(ProfileSkeleton, null),
                    clientData && React.createElement(ClientForm, { client: clientData, mutate: mutate }))))));
};
export default Client;
