var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Fragment, useEffect, useState } from "react";
import SideBar from "../Commons/SideBar";
import { __ } from "../../i18n/translation";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import ProfileForm from "./ProfileForm";
import { UpdateMember, useProfile } from "./useUsers";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PasswordForm from "./PasswordForm";
import ProfileSkeleton from "../Commons/ProfileSkeleton";
import SnackBar from "../Commons/Snackbar";
import Alert from "@mui/material/Alert";
const TabPanel = (props) => {
    const { children, value, index } = props, other = __rest(props, ["children", "value", "index"]);
    return (React.createElement("div", Object.assign({ role: "tabpanel", hidden: value !== index, id: `simple-tabpanel-${index}`, "aria-labelledby": `simple-tab-${index}` }, other), value === index && (React.createElement(Box, null, children))));
};
const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
const Profile = () => {
    const { isLoading, data: useUserData, refetch, isSuccess: useUserIsSuccess } = useProfile();
    const [value, setValue] = useState(0);
    const [userData, setUser] = useState(useUserData);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { mutate, data: useUpdateData, isError, isSuccess: UpdateUserIsSuccess } = UpdateMember();
    useEffect(() => {
        if (UpdateUserIsSuccess && useUpdateData) {
            setUser(useUpdateData);
            refetch();
        }
        if (useUserIsSuccess && useUserData) {
            setUser(useUserData);
        }
    }, [useUpdateData, useUserData, useUserIsSuccess, UpdateUserIsSuccess]);
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { title: __('Profile') }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Paper, { sx: { pl: 4, pb: 4 } },
                    React.createElement(Box, { sx: { borderBottom: 1, borderColor: 'divider', mr: 4 } },
                        React.createElement(Tabs, { value: value, onChange: handleChange, "aria-label": "basic tabs example" },
                            React.createElement(Tab, Object.assign({ label: "Vartotojo duomenis" }, a11yProps(0))),
                            React.createElement(Tab, Object.assign({ label: "Slapta\u017Eodis" }, a11yProps(1))))),
                    React.createElement(TabPanel, { value: value, index: 0 },
                        useUpdateData && React.createElement(SnackBar, { type: 1, msg: __('UpdateSuccess') }),
                        (isError) && React.createElement(Alert, { severity: "error" },
                            "  ",
                            __('emailExist'),
                            " "),
                        isLoading && React.createElement(ProfileSkeleton, null),
                        userData && React.createElement(ProfileForm, { user: userData, mutate: mutate })),
                    React.createElement(TabPanel, { value: value, index: 1 },
                        React.createElement(PasswordForm, null)))))));
};
export default Profile;
