import styled from 'styled-components';
import { theme } from '../theme';
export const Zone = styled.div `
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #7f8fa4;
  border: 1px solid #e6eaee;
  border-radius: 4px;
  padding: 5px;
  transition: border-color .3s;
  width: 100%;
  min-height: 170px;
  cursor: pointer;
`;
export const ZoneContent = styled.div `
  display: flex;
  flex-flow: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  margin: auto;
  border-radius: 4px;
  border: 2px dashed rgba(206, 208, 218, .5);
  padding: 5px;
  font-size: 14px;

  &:focus {
    outline: none !important;
  }
`;
export const ZoneP = styled.div `
  margin: auto;
  padding: 5px;
  font-size: 14px;
  text-align: center;

  svg {
    width: 60px;
    height: 60px;
  }
`;
export const ZoneList = styled.ul `
  list-style: none;
  padding: 0;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px ${theme.palette.grey[300]};
    margin: 5px;
    padding: 5px 10px;

    img {
      width: 80px;
      height: auto;
    }
  }
`;
export const ZoneSingle = styled.div `
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .img {
    width: 90%;
    height: auto;
  }
`;
