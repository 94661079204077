import { useFetch } from "../ApiService";
import Path from "../../Path";
import { useMutation, useQuery } from "react-query";
export const useSellers = ({ s = '' }) => {
    const getSellers = () => {
        return useFetch.get(`${Path.ApiSellers}/?s=${s}`);
    };
    return useQuery(['sellers'], getSellers);
};
export const useSellerCreate = () => {
    const fetchUser = (values) => {
        return useFetch.post(`${Path.ApiSeller}`, values);
    };
    return useMutation((values) => fetchUser(values), {});
};
