import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Fragment } from "react";
import SideBar from "../Commons/SideBar";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
const Analytics = () => {
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, null),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(Paper, null, "Analytics")),
                    React.createElement(Grid, { item: true, xs: 6 }))))));
};
export default Analytics;
