import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Fragment } from "react";
import SideBar from "../Commons/SideBar";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DrawerHeader } from "../../Theme/components/DrawerHeader";
import ExpensesForm from "./ExpenseForm";
const CreateExpense = () => {
    return (React.createElement(Fragment, null,
        React.createElement(CssBaseline, null),
        React.createElement(Box, { sx: { display: 'flex' } },
            React.createElement(SideBar, { title: 'Gautos PVP sąskaitos faktūros duomenys' }),
            React.createElement(Box, { component: "main", sx: { flexGrow: 1, p: 3 } },
                React.createElement(DrawerHeader, null),
                React.createElement(Paper, { sx: { pl: 4, pb: 4 } },
                    React.createElement(ExpensesForm, null))))));
};
export default CreateExpense;
